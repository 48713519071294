/**
 * The main store module.
 * @namespace store
 */
import Vue from 'vue';
import Vuex from 'vuex';
import commonConfig from '../common/constants';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    /**
     * The router route initially requested by user (not full url yet)
     * NOTE: Browser storage used for this, so here not used.
     */
    // requestedUrl: null,

    // JHI:
    logon: false,
    userIdentity: null,
    authenticated: false,

    /**
     * The application preferences per detected device.
     * The state of this is initialized with login but updated and refreshed with prefs changes in app UI.
     */
    deviceAppPrefs: null,

    // ////////////////////////////////////////
    // NEW Alert in store, for all new services
    // ////////////////////////////////////////
    // alertType: '',
    /**
     * The alert message must be an object with some message text inside in order to be detectable for
     * changes.
     * The changes of this object are influencing the show up of alert.
     */
    alertMessage: { msg: '' },

    /**
     * The new way of language selection from the top bar, like JHI pattern.
     * This field will be used on PUBLIC pages right now, after login, the user's profile fill be used.
     * Language in store is not initialized yet, browser detection delegated to translation.service.
     */
    currentLanguage: '',

    /**
     * The list of supported languages with translations (JHI pattern).
     */
    languages: {
      ...commonConfig.SUPPORTED_LANGUAGES,
      // NOTE: The following solution can be used to include optional languages but ONLY if the browser has them in preferred list
      // ...(window.navigator.languages.findIndex((lang) => lang.startsWith('sr')) >= 0
      //   ? { sr: { name: 'Srpski', timePickerFormat: '24hr' } }
      //   : {}),
    },

    /**
     * The minimum set of client needed system settings + information about the version - sysInfo.
     * NOTE: not all system settings are exposed to the client, but only the few of them - sysInfo.
     * In addition to sysInfo, one more property is available - versionInfo that contains a model of the last application
     * update.
     *
     * There is the list of app supported activities.
     */
    sysInfo: {},

    /**
     * The data used to track application progress bar.
     * Right now the implementation doesn't requires too much props because simple indeterminate progress is used.
     */
    progressBar: {
      loading: false,
    },

    /**
     * Toolbar for customizing html editor.
     */
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      // ['blockquote', 'code-block'],
      ['link'],
      // ['clean'],
    ],

    /**
     * The User Interface CI that depends on authority and accessed app content.
     * The value for uiCI is always kept in initializied state, at least to sys defaults, since some companies dont
     * have CI initialized.
     */
    uiCI: {
      /**
       * The UI company id reference. Used to identify the company which ui CI is involved.
       */
      companyId: null,

      /**
       * The flag that custom CI is enabled.
       */
      ciEnabled: false,
      /**
       * The headline configured for company CI. It can be empty if configured that way.
       */
      headline: '',
      /**
       * Initially, the colors are set to transparent, then changed when CI changes.
       */
      colors: {
        primary: '#00000000',
        pcomplement: '#00000000',
        accent: '#00000000',
        success: '#00000000',
        surface: '#00000000',
      },
      /**
       * The timestamp of the CI data that might help in proper resolving of updated content for
       * other parts of the app.
       */
      ts: new Date().getTime(),

      hasLogo: false,
    },
  },
  getters: {
    getAuthorities(state) {
      return state.userIdentity ? state.userIdentity.authorities || [] : [];
    },

    isEmployee(state, getters) {
      return getters.getAuthorities.indexOf('EMPLOYEE') !== -1 && state.userIdentity.employeeId > 0;
    },

    isKiosk(state, getters) {
      return getters.getAuthorities.indexOf('KIOSK') !== -1 && state.userIdentity.employeeId > 0;
    },

    isClientUser(state, getters) {
      return getters.getAuthorities.indexOf('USER') !== -1;
    },

    // eslint-disable-next-line jsdoc/require-param
    /**
     * Evaluates whether the visitor of the app is using the public access or it is a client user.
     */
    isPublicOrClient(state, getters) {
      return !state.userIdentity || getters.isClientUser;
    },

    // eslint-disable-next-line jsdoc/require-param
    /**
     * Evaluates whether the visitor of the app is using the public access or it is a client user
     * or a KIOSK company user that should have an UI just like a client user.
     */
    isUserPCK(state, getters) {
      return getters.isPublicOrClient || getters.isKiosk;
    },

    /**
     * Evaluates is the logged user Company Admin.
     * @param {object} state - The store state.
     * @param {object} getters - The store getters. NOTE: injected as second argument.
     * @returns {boolean} - Returns true if the logged user has CA authority.
     */
    isCA(state, getters) {
      return getters.getAuthorities.indexOf('CA') !== -1;
    },

    getEmployeeId(state) {
      return state.userIdentity ? state.userIdentity.employeeId : null;
    },

    /**
     * Gets the company id for logged user - if employee.
     * @param {object} state - The store state.
     * @returns {number|null} The company id or null if regular user.
     */
    getCompanyId(state) {
      // return state.user.employee ? state.user.employee.companyId : null;
      return state.userIdentity ? state.userIdentity.companyId : null;
    },

    /**
     * The getter for stored CI data.
     * @param {object} state - The store state.
     * @returns {object} - The limited CI attributes.
     */
    uiCI(state) {
      return state.uiCI;
    },

    /**
     * The timepicker format is evaluated based on user's language.
     * @param {object} state - The store state.
     * @returns {string} - The format for vuetify timepicker: ampm, 24hr.
     */
    timePickerFormat: (state) => {
      return state.languages[state.currentLanguage] ? state.languages[state.currentLanguage].timePickerFormat : 'ampm';
    },

    // JHI
    currentLanguage: (state) => state.currentLanguage,
    languages: (state) => state.languages,
    logon: (state) => state.logon,
    account: (state) => state.userIdentity,
    authenticated: (state) => state.authenticated,

    // ////////////////////////////////////////
    // NEW Alert in store, for all new services
    // NOTE: the type of message (error, info) is determined based on message prefix: error or message
    // so the alert type is not needed
    // ////////////////////////////////////////
    // alertType: (state) => state.alertType,
    alertMessage: (state) => state.alertMessage,

    sysInfo(state) {
      return state.sysInfo;
    },

    getPageSize(state) {
      return state.sysInfo.pageSize;
    },

    getPasswordMinLength(state) {
      if (typeof state.sysInfo.passwordMinLength !== 'undefined') {
        return state.sysInfo.passwordMinLength;
      }

      return 8;
    },

    /**
     * Formats application version for display purposes.
     * @param {object} state - The store state.
     * @returns {string} - The formatted version.
     */
    fullVersion(state) {
      const { versionInfo: vInfo } = state.sysInfo;
      if (vInfo) {
        return `v${vInfo.major}.${vInfo.minor}.${vInfo.patch}`;
      }
      return '';
    },

    /**
     * Formats last application update for display purposes.
     * @param {object} state - The store state.
     * @returns {string} - The formatted app update.
     */
    versionNote(state) {
      const { versionInfo: vInfo } = state.sysInfo;
      if (vInfo) {
        return `${vInfo.description}`;
      }
      return '';
    },

    /**
     * Getter for progress bar state.
     * @param {object} state - The store state.
     */
    progress(state) {
      return state.progressBar;
    },

    /**
     * Getter for account app prefs per device.
     * @param {object} state - The store state.
     * @returns {object} - The app preferences per device.
     */
    devicePrefs(state) {
      return state.deviceAppPrefs;
    },
    devicePrefsAgenda(state) {
      return state.deviceAppPrefs ? state.deviceAppPrefs.agenda : undefined;
    },

    customToolbar(state) {
      return state.customToolbar;
    },
  },
  mutations: {
    // JHI
    currentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
    },
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
      state.deviceAppPrefs = identity.devicePrefs;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
      state.deviceAppPrefs = null;

      // reset CI
      state.uiCI = {
        companyId: null,
        ciEnabled: false,
        hasLogo: false,
        /**
         * The headline configured for company CI. If not specified, the app will fallback to translation.
         */
        headline: '',
        /**
         * Initially, the colors are set to transparent, then changed when CI changes.
         */
        colors: state.sysInfo.ciColors,
      };
    },
    languages(state, newLanguages) {
      state.languages = { ...newLanguages };
    },

    /**
     * The mutator for UI CI store data.
     * @param {object} state - The store state.
     * @param {object?} newCI - The new values for CI, received from API or other parts of the app, like management.
     * If newCI is not specified or null, the defaults would be restored.
     */
    setUICI(state, newCI = undefined) {
      // take care to allow partial update of this store value
      if (newCI && newCI.ciEnabled) {
        const { ciEnabled, hasLogo } = newCI;
        const { headline } = newCI;
        const colors = newCI.colors || state.uiCI.colors;
        // from company id, append timestamp so that logo can be refreshed
        const companyId = newCI.companyId || state.uiCI.companyId;
        state.uiCI = { ciEnabled, headline, colors, companyId, ts: new Date().getTime(), hasLogo };

        // for company stuff, update their ci company data, since it is used to refresh the CI in the router?
        // TODO: v0.14.0 - we will see about this
      } else {
        // reset CI
        state.uiCI = {
          companyId: null,
          ciEnabled: false,
          hasLogo: false,
          headline: state.uiCI.headline,
          colors: state.sysInfo.ciColors,
          ts: new Date().getTime(),
        };
      }
    },

    /**
     * The mutator for UI CI store data - the timetamp of a change.
     * It can be usefull in cases when the UI needs to be refreshed, eg in case of new logo.
     * @param {object} state - The store state.
     */
    setNewTsForUICI(state) {
      state.uiCI.ts = new Date().getTime();
    },

    // JHI
    // ////////////////////////////////////////
    // Alerts
    // ////////////////////////////////////////

    initAlert(state) {
      state.alertMessage = {};
    },
    setAlertMessage(state, alertMessage) {
      state.alertMessage = alertMessage;
    },

    // ////////////////////////////////////////
    // Progress bar
    // ////////////////////////////////////////

    initProgress(state) {
      state.progressBar = {
        loading: false,
      };
    },

    setProgress(state, loading) {
      state.progressBar.loading = loading;
    },

    // Custom sys info for all needed sys info
    sysInfo(state, sysInfo) {
      state.sysInfo = Object.freeze(sysInfo);
      // also initialize the uiCI data (as default?)
      // state.uiCI = { ...sysInfo.uiCI };
    },

    devicePrefs(state, dp) {
      state.deviceAppPrefs = { ...dp };
    },
  },

  plugins: [],
});

export default store;
