const deepGet = (obj, keys) => keys.reduce((xs, x) => xs?.[x] ?? null, obj);

// The helper that might be useful.
const deepGetByPath = (obj, pth) =>
  deepGet(
    obj,
    pth
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t) => t !== ''),
  );
// Using simple deep-merge
const merge = (...objects) => {
  const deepCopyObjects = objects.map((object) => JSON.parse(JSON.stringify(object)));
  return deepCopyObjects.reduce((merged, current) => ({ ...merged, ...current }), {});
};

const isObject = (object) => {
  return object != null && typeof object === 'object';
};

/**
 * The helper to compare two objects, checking all properties.
 * @param object1
 * @param object2
 * @returns {boolean}
 */
const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export { deepGetByPath, merge, isDeepEqual };
