// NOTE: fonts are preloaded by using custom configuration in order to tune up the performance of app loading.
// check for details in fonts-def.css

// NOTE: TC CUSTOM: The main fonts are not loaded this way any more - npm lib + import
// Instead, the optimal fonts are downloaded and stored in public and included as preload in index.html
// import 'roboto-fontface/css/roboto/roboto-fontface.css';
// import 'open-sans-fontface/open-sans.css';

// NOTE: the material design icons could be preloaded like fonts, new issue reserved for this
// import 'material-design-icons-iconfont/dist/material-design-icons.css';

import Vue from 'vue';
import Router from 'vue-router';
// include directive for handling click outside of component
// NOTE: importing this plugin as directive introduces issues with autocompletes -> thus used locally in alert component.
// import vClickOutside from 'v-click-outside';

import './plugins/vuetify';
import VueCurrencyInput from 'vue-currency-input';
// including vue-cli generated service worker registration
import './registerServiceWorker';

import VeeValidate from 'vee-validate';

import App from './app.vue';
import router from './router';

import * as config from './shared/config';

// Filters
import Filters from './filters/filters';

// Mixins
import systemMixin from './mixins/system.mixin';
import fmtMixin from './mixins/fmtMixin';
import formMixin from './mixins/formMixin';
import securityMixin from './mixins/security.mixin';
import timeMixin from './mixins/time.mixin';

import store from './store/store';

// Services
import SystemService from './service/system.service';
import TranslationService from './service/translation.service';
import AccountService from './service/account.service';
import AlertService from './service/alert.service';
import AppNotificationsService from './service/app-notifications.service';
import ClientService from './service/client.service';
import GeoService from './service/geo.service';
import CompanyService from './service/company.service';
import BookingsService from './service/bookings.service';
import EaOrderService from './service/ea-order.service';
import CompanyManageService from './service/company-manage.service';
import EmployeeManageService from './service/employee-manage.service';
import UserManageService from './service/user-manage.service';
// import DemoService from './service/demo.service';

// NOTE: TC CUSTOM: translations might be needed for add to home screen banner, initialized before app.
const i18n = config.initI18N(Vue);
// NOTE: the store could be configured within config like in JHI, right now the store is include from separate file.
// const store = config.initVueXStore(Vue);

// must have services
const translationService = new TranslationService(store, i18n);
const alertService = new AlertService(store);

// other services. NOTE: account service needs to be initialized after the vue app
const appNotificationsService = new AppNotificationsService({ store, router, alertService, translationService });
// system service init depends on already initialized axios
const systemService = new SystemService(store);
const accountService = new AccountService({ store, router, alertService, translationService });
const clientService = new ClientService({ store, router });
const geoService = new GeoService({ store, router });
const companyService = new CompanyService({ store, router, appNotificationsService });
const bookingsService = new BookingsService({ store, router });
const eaOrderService = new EaOrderService({ store, router });
const companyManageService = new CompanyManageService({ store, router });
const employeeManageService = new EmployeeManageService({ store, router, appNotificationsService });
const userManageService = new UserManageService({ store, router });
// const demoService = new DemoService({ store });

// NOTE: TC CUSTOM: right now, only interceptors are initialized this way and not the complete vue app.
// AND for convenience, the vue app is initialized after creating services instances !
// NOTE: in further JHI revision, the initialization of vue app can be delegated to following function in greater degree than now.
config.initVueApp(Vue, { alertService, systemService, accountService });

/**
 * The config for vee-validate.
 */
const veeValidateConfig = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  // NOTE: customizing client validation - validate only on blur only
  // events: 'blur',
  fieldsBagName: 'fields',
  // i18n, // the vue-i18n plugin instance - will be initialized on translation service init
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  // locale: { en }, // We dont need to specify default here since we are performing lazy localization in transl service ?
  strict: true,
  validity: false,
};

Vue.use(systemMixin);
Vue.use(VeeValidate, veeValidateConfig);
Vue.use(VueCurrencyInput, {});
Vue.use(Router);
Vue.use(fmtMixin);
Vue.use(Filters);
Vue.use(formMixin);
Vue.use(securityMixin);
Vue.use(timeMixin);

// Vue.use(vClickOutside);
// Vue.use(filesMixin);

// IMPORTANT: Initializing Vue instance AFTER the account service has performed ALL needed initializations in order to
// have security/redirection info
// Apparently, the Vue is allowing that app is initialized async ?
export default (async () => {
  // init system service first
  await systemService.init();
  // then account service
  await accountService.init();
  // then make the instance of Vue app and return it as a result
  const vueInstance = new Vue({
    el: '#app',
    i18n,
    router,
    provide: {
      systemService: () => systemService,
      accountService: () => accountService,
      alertService: () => alertService,
      translationService: () => translationService,
      appNotificationsService: () => appNotificationsService,
      clientService: () => clientService,
      geoService: () => geoService,
      companyService: () => companyService,
      bookingsService: () => bookingsService,
      eaOrderService: () => eaOrderService,
      companyManageService: () => companyManageService,
      employeeManageService: () => employeeManageService,
      userManageService: () => userManageService,
      // demoService: () => demoService,
    },
    store,
    render: (h) => h(App),
    // created for complete app
    created() {
      // inject vuetify instance into translation service
      translationService.vuetifyInstance = this.$vuetify;

      // NOTE: TC CUSTOM: inject custom validator for comparing passwords
      this.$validator.extend('verify_password', {
        getMessage: () => this.$t('error.password_is_not_valid'),
        validate: (value) => {
          const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])');
          return strongRegex.test(value);
        },
      });
    },
    // the very global method refs available directly on the vue instance
    methods: {
      closeTermsDlg() {
        // close the dialog
        this.$children[0].$refs.termsDlg.close();
        // then route to contact form
        this.$router.push({ name: 'contactFormPage' });
      },
    },
  });
  // export some globally available components (accessible out of vue app scope)
  window.document.closeTermsDlg = vueInstance.closeTermsDlg;
  // NOTE: TC CUSTOM: allowing global reference to this vue app instance (currently used in axios interceptor for worker service update)
  window.vueGlobalInstance = vueInstance;
  return vueInstance;
})();
