// Custom SRB latin translations for vuetify component labels
Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = {
  dataIterator: {
    rowsPerPageText: 'Stavki po stranici:',
    rowsPerPageAll: 'Sve',
    pageText: '{0}-{1} od {2}',
    noResultsText: 'Ni jedan podatak nije pronađen',
    nextPage: 'Sledeća stranica',
    prevPage: 'Prethodna stranica',
  },
  dataTable: {
    rowsPerPageText: 'Redova po stranici:',
  },
  noDataText: 'Nema dostupnih podataka',
  carousel: {
    prev: 'Prethodni',
    next: 'Naredni',
  },
};
