import { mapGetters, mapMutations } from 'vuex';

import appAlerts from './cmp/alerts.vue';
import appHeader from './cmp/app-header.vue';
import AppFooter from './cmp/app-footer.vue';
import LeavePageDialog from './cmp/form/leave-page-dialog.vue';
import TermsDialog from './cmp/terms-dialog.vue';

const App = {
  inject: ['companyService', 'accountService'],
  data() {
    return {
      deferredPrompt: null,
      // the promote installation dialog (bottom sheet)
      promoteDlg: {
        show: false,
        dismissed: false,
      },
      // the installation guide dialog (bottom sheet)
      guideDlg: {
        show: false,
      },
      /**
       * The dialog for requesting support.
       */
      supportReqDlg: {
        /**
         * Show/hide flag.
         */
        show: false,
        /**
         * The one and only form element for requesting support.
         * Will be initialized by company phone number.
         */
        phoneNumber: '',
        /**
         * Initialized by company name on show.
         */
        companyName: '',
      },
    };
  },
  /**
   * The application is providing some common functionality to all child components.
   */
  provide() {
    return {
      confirmLeavePage: () => this.$refs.leavePageDlg.confirmLeavePage(),
      showAppDoc: (docKey) => this.$refs.termsDlg.showDoc(docKey),
      supportReq: () => this.showSupportReqDlg(),
    };
  },
  components: {
    appAlerts,
    appHeader,
    AppFooter,
    LeavePageDialog,
    TermsDialog,
  },
  computed: {
    ...mapGetters(['currentLanguage', 'account', 'getCompanyId', 'uiCI']),
    lang() {
      return this.currentLanguage;
    },
    isCustomUICI() {
      return this.uiCI.ciEnabled;
    },
  },

  created() {
    this.promoteInstallation();

    // initialize CI on authentication if user is employee
    // NOTE: this is important to apply CI when the browser page is completely refreshed for company users
    if (this.account?.employee) {
      const { ci } = this.account.employee.company;
      this.setUICI(ci);
    }
  },
  mounted() {},
  watch: {
    /**
     * NOTE: This is the simplest solution to popup the updated language in the store into the URL.
     * The rest of the language detection change and selection logic is now in client's translation.service.js.
     * @param {string} newVal - The new language key.
     */
    lang(newVal) {
      // console.log('replacing route', newVal);
      this.$router.replace({
        query: {
          ...this.$router.currentRoute.query,
          langKey: newVal,
        },
      });
    },
    uiCI(newVal) {
      this.applyCI(newVal);
    },
    // 'uiCI.colors': function (newVal) {
    //   console.log('watch headline', newVal);
    // },
  },
  methods: {
    ...mapMutations(['setUICI']),

    /**
     * Prepare the setup to promote the app installation on supported platforms.
     */
    promoteInstallation() {
      // Detect when the PWA was successfully installed
      /*
      window.addEventListener('appinstalled', (evt) => {
        console.log('a2hs installed');
      });
*/

      // Track how the PWA was launched, if already standalone, skip promotions
      if (navigator.standalone) {
        console.log('Launched: Installed (iOS)');
      } else if (matchMedia('(display-mode: standalone)').matches) {
        console.log('Launched: Installed');
      } else {
        console.log('Launched: Browser Tab');
        // Custom: in case of iphone, show the install dialog should be suggestion ?
        // Listen for the beforeinstallprompt event
        if (this.isChrome || this.isNewEdge) {
          window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            // showInstallPromotion();
            // console.log('Show install promotion');
            this.showPromoteDlg();
          });
        } else {
          // in case of other browsers and oses, the messages will be configured by appropriate conditions on page
          this.showPromoteDlg();
        }
      }
    },

    /**
     * React on user's confirmation to install the app.
     */
    onInstall() {
      // In-app installation flow
      // Hide the app provided install promotion
      this.hidePromoteDlg();

      // NOTE: used to test display of iphone dialog on non-iphone platform
      // this.showGuideDlg();
      if (!this.deferredPrompt) {
        // The deferred prompt isn't available.
        return;
      }

      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        // just completely dismiss the dialog , no special logic right now on made choice
        this.dismissPromoteDlg();
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        this.deferredPrompt = null;
        /*
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        */
        return choiceResult;
      });
    },

    // promote dialog
    showPromoteDlg() {
      this.promoteDlg.show = true;
    },
    hidePromoteDlg() {
      this.promoteDlg.show = false;
    },
    /**
     * Completely dismisses the promote dialog so it cannot appear again.
     */
    dismissPromoteDlg() {
      this.promoteDlg.dismissed = true;
    },

    // guide user (in case of iphone...)
    showGuideDlg() {
      this.hidePromoteDlg();
      this.guideDlg.show = true;
    },
    hideGuideDlg() {
      this.guideDlg.show = false;
    },

    /**
     * Show the dialog for requesting support.
     * In addition, load the fresh info about the company, needed for phoneNumber.
     */
    async showSupportReqDlg() {
      const companyId = this.getCompanyId;
      if (companyId) {
        const company = await this.companyService().retrieveCompany(companyId, { filter: { sflt: 'minData' } });
        this.supportReqDlg.show = true;
        this.supportReqDlg.companyName = company.name;
        this.supportReqDlg.phoneNumber = company.phoneNumber;
      }
    },

    /**
     * Executes the request for support.
     * @returns {Promise<void>} - The empty promise.
     */
    async doRequestSupport() {
      await this.companyService().supportRequest(this.supportReqDlg.phoneNumber);
      this.hideSupportReqDlg();
    },

    hideSupportReqDlg() {
      this.supportReqDlg.show = false;
      // clear associated data
      this.supportReqDlg.phoneNumber = '';
      this.supportReqDlg.companyName = '';
    },

    /**
     * Applies settings from company's CI to parts of the UI.
     * NOTE: Used through watcher of UI CI in app store.
     * @param {object} ci - The ui CI based on company CI settings/assets and system defaults.
     */
    applyCI(ci) {
      const { primary, pcomplement, success, accent, surface } = ci.colors;

      this.$vuetify.theme.primary = primary;
      this.$vuetify.theme.pcomplement = pcomplement;
      this.$vuetify.theme.success = success;
      this.$vuetify.theme.accent = accent;
      this.$vuetify.theme.surface = surface;
    },
  },
};
export default App;
