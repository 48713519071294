import { mapGetters } from 'vuex';

export default {
  inject: ['translationService', 'accountService', 'companyService', 'supportReq'],
  components: {},
  data() {
    return {
      /**
       * The company might be needed for displaying some info about the company.
       */
      company: null,
    };
  },

  created() {},
  computed: {
    ...mapGetters(['uiCI', 'sysInfo']),

    /**
     * The company identity is identified in all possible ways: employee, query param, route path.
     * @type {number}
     */
    companyId() {
      return this.account?.companyId || this.$route.query.companyId;
    },
  },
};
