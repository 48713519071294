export default {
  /**
   * The helper function that actually sets the cookie and work on iOS, should be used as a workaround in the future.
   *
   * StackOverflow notes:
   * I've just come across this problem with cookies being persistent on Android / Desktop devices but not on iOS11 when
   * tested on a production server. The solution seemed to be defining the domain of the cookie:
   * iOS11 seems to be much more locked down in terms of what cookies it accepts. I can find lots of marketing blurb about it
   * being better for privacy but very little technical detail about how to implement things (e.g. Persistent login / SSO) properly
   * in light of the new restrictions. Can anyone recommend any useful links?
   * @param {string} cname - The cookie name.
   * @param {string} cvalue - The cookie value.
   * @param {number} exdays - The expiration days. If not specified, the expiration will not be set, thus -> session cookie.
   */
  setCookie(cname, cvalue, exdays = undefined) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = '';
    if (exdays > 0) {
      expires = `expires=${d.toUTCString()}`;
    }
    document.cookie = `${cname}=${cvalue};${expires};domain=${window.location.hostname};path=/`;
  },
  /**
   * Gets the cookie value by specified name.
   * @param {string} cname - The cookie name.
   * @returns {string|null} The value of found cookie or null if not found.
   */
  getCookie(cname) {
    if (document.cookie && document.cookie.indexOf(cname) >= 0) {
      // The commended code is from MDN
      // return document.cookie.split('; ').find((row) => row.startsWith(cname));
      return document.cookie.split(`${cname}=`)[1].split(';')[0];
    }
    return null;
  },
  /**
   * The helper function that actually deletes the cookie for the app.
   * @param {string} cname - The cookie name.
   */
  deleteCookie(cname) {
    document.cookie = `${cname}=;max-age=-1;domain=${window.location.hostname};path=/`;
  },
};
