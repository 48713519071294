// The confirmation dialog component for displaying warning message about leaving the current page and discarding changes.
export default {
  props: {
    // NOTE: these options can be used later in order to set custom message instead of a default one - preps for future improvements.
    options: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default() {
        return {
          /**
           * The translation key used for translating the name of the page which is about to be abandoned by user.
           */
          pageNameKey: '',
        };
      },
    },
  },
  data() {
    return {
      // internal flag that dialog is visible or not
      visible: false,
      // the user confirmed the route leave
      confirmed: false,
      /**
       * The promise resolve that is about to be initialized when user answers.
       */
      resolve: null,
    };
  },
  components: {},
  computed: {},
  methods: {
    /**
     * The method that other components can use to trigger the dialog display.
     */
    confirmLeavePage() {
      this.visible = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    /**
     * Handles click on cancel or ok buttons.
     * @param {boolean} leave - The answer to leave the route.
     */
    onAnswer(leave) {
      this.visible = false;
      this.resolve(leave);
    },
  },
};
