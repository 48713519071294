/**
 * The Mixin with security and identification support methods.
 */
const securityMixin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        hasAnyAuthority(authoritiesParam) {
          let authorities = authoritiesParam;
          if (typeof authoritiesParam === 'string') {
            authorities = [authoritiesParam];
          }
          if (!this.$store.getters.authenticated || !this.$store.getters.getAuthorities) {
            return false;
          }

          for (let i = 0; i < authorities.length; i += 1) {
            if (this.$store.getters.getAuthorities.includes(authorities[i])) {
              return true;
            }
          }

          return false;
        },
        /**
         * Evaluates that user (implements user interface) has any of specified authorities.
         * @param {object} user - The user item that implements user interface (has authorities field).
         * @param {string|Array<string>} authoritiesParam - The authorities param to check, string or arrays of them.
         */
        userHasAuthority(user, authoritiesParam = []) {
          let authorities = authoritiesParam;
          if (typeof authoritiesParam === 'string') {
            authorities = [authoritiesParam];
          }
          if (user && user.authorities && user.authorities.length) {
            for (let i = 0; i < authorities.length; i += 1) {
              if (user.authorities.includes(authorities[i])) {
                return true;
              }
            }
            return false;
          }
          return false;
        },
        /**
         * Evaluates weather the current user can manage own and other's holidays.
         * @param {number} employeeId - The serial id of the employee for which one the permissions need to be checked.
         */
        canManageEmployeeHolidays(employeeId) {
          if (this.hasAnyAuthority(['CA'])) {
            return true;
          }
          if (this.hasAnyAuthority(['EMPLOYEE']) && this.$store.getters.getEmployeeId === employeeId) {
            return true;
          }
          return false;
        },
      },
      computed: {
        /**
         * The managed company id is evaluated based on authority of logged user.
         * The employee with company admin authority will have company id initialized from the model, while
         * system admin can have this initialized form the route.
         *
         * NOTE: this method can be used ONLY within company management routes (for now) because id param name is used for company id.
         */
        routeCompanyId() {
          return this.$router.currentRoute.params.id
            ? this.$router.currentRoute.params.id
            : this.$store.getters.getCompanyId;
        },
      },
    });
  },
};

export default securityMixin;
