import axios from 'axios';
import queryString from 'query-string';

/**
 * The bookings service.
 */
export default class BookingsService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
    // this.server = this.store.modules.server;
    // this.init();
  }

  /**
   * Load client's orders with bookings per day and per employee.
   * NOTE: the company is identified by logged user. This prevents possibility to peek into other company
   * data.
   * @param {{timeFilter: string}} loadingParams - The object with loading params.
   * @param {string} loadingParams.timeFilter - The time filter - enumerated value for relative time: 'ACTUAL' and 'PAST'.
   * @param {number} loadingParams.companyId - The companyId filter.
   * @returns {Promise<any>} The 'promised' array of booking DTO info objects.
   */
  async loadOrders({ timeFilter, companyId }) {
    const res = await axios.get(`api/orders?timeFilter=${timeFilter}&companyId=${companyId}`);
    return res.data;
  }

  /**
   * Load order details.
   * @param {number} orderId - The order's serial id.
   * @returns {Promise<object>} The 'promised' Order object.
   */
  async loadOrderDetails(orderId) {
    const res = await axios.get(`api/orders/${orderId}`);
    return res.data;
  }

  /**
   * Confirm the order.
   * @param {object} frm - The order's confirmation form that includes confirmation key.
   * @returns {Promise<object>} The 'promised' Order confirmation result object.
   */
  async confirmOrder(frm) {
    const res = await axios.post(`api/order-confirmation`, frm);
    return res.data;
  }

  /**
   * Load company's bookings data per day and per employee.
   * NOTE: the company is identified by logged user. This prevents possibility to peek into other company
   * data.
   * @param {object} loadingParams - The object with booking loading params.
   * @param {string} loadingParams.date - The date for which bookings are requested expressed in ISO format (date only) without timezone.
   * @param {number?} loadingParams.employeeId - The optional param of employee serial id that can be used for
   * bookings filtering - optional.
   * @param {number?} loadingParams.days - The number of days for which the agenda needs to be preloaded (calendar mode).
   * @param {boolean?} loadingParams.includeEdgeNAs - Should the edge Not Available slots be included in NA slots.
   * @returns {Promise<any>} The 'promised' array of booking DTO info objects.
   */
  async loadCompanyBookings({ date, employeeId, days = undefined, includeEdgeNAs = undefined }) {
    const query = queryString.stringify({ date, employeeId, days, includeEdgeNAs });

    const res = await axios.get(`api/companies/${this.store.getters.getCompanyId}/agenda?${query}`);
    return res.data;
  }

  /**
   * Load company's bookings data for today's QMS operating post agenda.
   * @param {object} filterParams - The object with booking loading params.
   * @returns {Promise<any>} The 'promised' array of booking DTO info objects.
   */
  async loadCompanyBookingsForQMSOperatingPost(filterParams) {
    const query = queryString.stringify(filterParams);

    const res = await axios.get(`api/companies/${this.store.getters.getCompanyId}/qms-cmd?${query}`);
    return res.data;
  }

  /**
   * Loads the data for company screen depending on the screen type and related resources.
   * @param {object} filterParams - The filtering params.
   * @returns {{slots: Array<object>}} - The screen data DTO.
   */
  async loadCompanyScreenData(filterParams) {
    const query = queryString.stringify(filterParams);

    const res = await axios.get(`api/companies/${this.store.getters.getCompanyId}/screen-data?${query}`);
    return res.data;
  }

  /**
   * Creates booking order and provides the result.
   * @param {number} companyId - The company for which one the booking request will be made.
   * @param {object} bookingDTO - The booking DTO (actually ShoppingRequestDTO with multi service support).
   * @param {object | null} order - The existing order that has been edited or used as a template for re-using.
   * @param {string?} aKey - The access key used for external order update.
   * @returns {Promise<object>} - The booking result DTO.
   */
  async bookRequest(companyId, bookingDTO, order = null, aKey) {
    const { scp, brFlt } = this.router.currentRoute.query;
    // convert the DTO to API dto (Shopping request)
    const requestDTO = {
      ...(order ? { id: order.id } : {}),
      companyId,
      // NOTE: the time will be passed as is - zulu time
      items: bookingDTO.items.map((item) => ({
        time: item.time,
        sid: item.service.id,
        // rid: item.resource.id,
        rids: item.resources.map((res) => res.id),
        // NOTE: support for composite bookings
        items: item.items
          ? item.items.map((csSlotItem) => ({
              time: csSlotItem.time,
              sid: csSlotItem.service.id,
              duration: csSlotItem.service.srvType === 'SPACING' ? csSlotItem.service.duration : undefined,
              rids: csSlotItem.resources.map((res) => res.id),
            }))
          : undefined,
      })),
      // append optional form fields: RFI, new user info...
      ...bookingDTO.frm,

      // scp is appended as signed custom parameter for booking creation
      scp,
      // brFlt - booking request filter is supplied if booking is done within filter context
      brFlt,
    };
    // The invoked method depends on the existing order and external access
    let res;
    if (order) {
      if (aKey) {
        res = await axios.post(`api/orders-cmd/?cmd=update`, { ...requestDTO, aKey });
      } else {
        res = await axios.put(`api/orders`, requestDTO);
      }
    } else {
      res = await axios.post(`api/orders`, requestDTO);
    }
    return res.data;
  }

  /**
   * Sets a remark for an order.
   * NOTE: setting remark has been disabled in v0.3.0.
   * @param {object} remarkDTO - The simple DTO with all needed data to update remark.
   * @returns {Promise<object>} - The booking remark result DTO.
   */
  /*
  async setBookingRemark(remarkDTO) {
    // const res = await axios.patch(`api/bookings/${remarkDTO.bookingId}/remark`, remarkDTO);
    const res = await axios.patch(`api/orders/${remarkDTO.orderId}/remark`, remarkDTO);
    return res.data;
  }
*/

  /**
   * Cancels the order with custom reason text.
   * @param {number} orderId - The order's serial id.
   * @param {string?} cancelReason - The cancel reason text provided by resource to client user.
   * @returns {Promise} - The empty promise.
   */
  async deleteOrder(orderId, cancelReason = undefined) {
    // delete, then reload
    const res = await axios.delete(`api/orders/${orderId}`, {
      headers: {
        // custom header for cancel message in case of resource user
        ...(this.store.getters.isEmployee && cancelReason
          ? { 'x-custom-message': encodeURIComponent(cancelReason) }
          : {}),
      },
    });
    return res.data;
  }

  // ///////////
  // Slots

  /**
   * Retrieves free slots or slot groups based on specified info in DTO.
   * @param {number} companyId - The company serial id.
   * @param {object} requestDTO - The availability request DTO.
   * @returns {Promise<Array<object>>} - The promised array of free slot DTOs.
   */
  async retrieveSlots(companyId, requestDTO) {
    // we need to convert requested resources array into csv to be able to have this custom format in query string
    // adjust to API, not specified resource ids should have a form ['0']
    const dto = {
      ...requestDTO,
      // override rids field
      // rids: (requestDTO.rids || []).map((ridArr) => (ridArr.length ? ridArr : [0]).join(',')),

      // override rids field, convert it in order to be able to transmit array of arrays in query string
      rids: (requestDTO.rids || []).map((ridArrPerSrv) => {
        if (ridArrPerSrv.length) {
          // check for the depth of nested arrays, in case of resources for items of c-service
          // for atomic service selection, the first item of array must be number
          // if there are arrays inside this array, map them to dash-separated numbers
          if (Number.isInteger(ridArrPerSrv[0])) {
            // we have an array of ids for atomic services, separating multi res service items by - sign
            return ridArrPerSrv.join('-');
          }
          // prepare for c-service rids in a specific way
          return ridArrPerSrv
            .map((arrEl) => {
              if (Array.isArray(arrEl)) {
                return arrEl.join('-');
              }
              return arrEl;
            })
            .join('_');
        }
        return [0];
      }),
      // rids: (requestDTO.rids || []).map((ridArr) => (ridArr.length ? ridArr : [0]).join('_')),

      // The DTO's ssIds is an array of elements which might be: CSV strings or array of CSV strings (for c-service)
      ssIds: requestDTO.ssIds.map((ssIdsEl) => {
        if (Array.isArray(ssIdsEl)) {
          // repack this one to string as well
          return ssIdsEl.join('_');
        }
        // NOTE: using 0 to indicate not specified selected solution.
        return ssIdsEl ? [ssIdsEl] : 0;
      }),
    };
    const res = await axios.get(`api/i/${companyId}/slots?${queryString.stringify(dto, { arrayFormat: 'bracket' })}`);
    return res.data;
  }

  /**
   * Prints the order PDF with appropriate content depending on used report (right now only the ticket).
   * @param {number} orderId - The order serial id.
   * @returns {Promise<Buffer>} - The promise with prepared PDF buffer.
   */
  async printOrderTicketPdf(orderId) {
    // download over AJAX
    const res = await axios.get(`api/orders-pdf/${orderId}`, {
      responseType: 'arraybuffer',
    });

    // const hdrs = res.headers;
    // const blob = new Blob([res.data], { type: hdrs['content-type'] });

    const buf = Buffer.from(res.data);
    // const b64 = buf.toString('base64');
    // console.log('b64', b64);
    return buf;
    // return blob;
    // URL.revokeObjectURL(url)
  }
}
