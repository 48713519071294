import dayjs from 'dayjs';

/**
 * The internal format for initializing UI datepicker.
 */
const DATE_PICKER_INT_FMT = 'YYYY-MM-DD';

/**
 * The internal format for initializing UI datepicker.
 */
const TIME_PICKER_INT_FMT = 'HH:mm';

export default {
  /**
   * Converts the duration expressed in units into picker appropriate format.
   * @param {number} value - The amount of duration expressed in 'unit'.
   * @param {string} unit - The used unit, eg 'minutes'.
   */
  forDurationPicker(value, unit) {
    return dayjs.duration(value, unit).format('HH:mm');
  },

  /**
   * Converts the duration value stored in time picker into integer value expressed in minutes.
   * @param {string} strValue - The formatted string in datepicker 24hr format used for duration representation.
   * @returns {number} The number of minutes.
   */
  fromDurationPicker(strValue) {
    const time = dayjs(strValue, TIME_PICKER_INT_FMT);
    return time.hour() * 60 + time.minute();
  },

  DATE_PICKER_INT_FMT,

  TIME_PICKER_INT_FMT,
};
