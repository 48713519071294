import axios from 'axios';
import queryString from 'query-string';

/**
 * The service to support geo-specific logic, like info about cities and countries.
 */
export default class GeoService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
  }

  /**
   * Loads system supported countries.
   * @param {object} filter - The composite filter applied for finding countries.
   * @param {string} filter.searchKey - The search key for filtering countries. If not specified, all countries will be fetched.
   * @returns {Promise<[]<object>>} - The promise with object of retrieved countries.
   */
  async retrieveCountries(filter = { searchKey: undefined }) {
    const filterQuery = queryString.stringify(filter, {});
    const res = await axios.get(`api/countries?${filterQuery}`);
    return res.data;
  }

  /**
   * Invokes REST API to retrieve cities for specified country.
   * @param {object} filter - The composite filter applied for finding cities.
   * @param {string} filter.countryCode - The country code by ISO ALPHA3. If not specified, the request will not be sent.
   * @param {string|undefined} filter.cityName - The internal coded name for city.
   * Used in case when needed to pre-initialize drop down with translated value from server.
   * @param {string|undefined} filter.searchKey - The search key.
   * @returns {Promise<[]<object>>} - The promise with object of retrieved cities.
   */
  async retrieveCities(filter) {
    // initiate REST request if basic pre-requisites for search are satisfied
    if (filter.countryCode && (!!filter.cityName || !!filter.searchKey)) {
      const filterQuery = queryString.stringify(filter, {});
      const res = await axios.get(`api/cities?${filterQuery}`);
      return res.data;
    }
    return [];
  }
}
