import dayjs from 'dayjs';
import pickerUtils from '../common/pickerUtils';

const { TIME_PICKER_INT_FMT } = pickerUtils;

/**
 * @module mixins/formMixin
 */
export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        /**
         * Detects that any of the form fields is touched.
         * @returns {boolean} - True if some of the form fields is touched.
         */
        isFormTouched() {
          return Object.keys(this.fields).some((key) => this.fields[key].touched);
        },
      },
      methods: {
        /**
         * NOTE: This function should give advantage to the v-model's trim modifier.
         * However, the v-model.trim would require some kind of forceUpdate of the component in some cases.
         * @function trimWhiteSpace
         * @param {object} formObject - The form object to trim.
         * @returns {object} - The object with trimmed whitespaces on string fields.
         */
        trimWhitespace(formObject) {
          if (typeof formObject === 'string') {
            return formObject.trim();
          }

          const trimmedObject = {};

          Object.keys(formObject).forEach((key) => {
            if (typeof formObject[key] === 'string') trimmedObject[key] = formObject[key].trim();
            else trimmedObject[key] = formObject[key];
          });

          return trimmedObject;
        },
        /**
         * Support for time picker, returns the filter function for filtering the minutes only to allowed ones.
         */
        timePickerAllowedMinutes() {
          return (m) => m % 5 === 0;
        },

        /**
         * Evaluates the min value for time picker when time picker selection depends on selection of start date/time,
         * like in ranges.
         * @param {object} frm - The form with specific date and time range fields.
         * @param {string} frm.dateFrom - The internally formatted 'from' date by reference datepicker.
         * @param {string} frm.dateTo - The internally formatted 'to' date by reference datepicker.
         * @param {string} frm.timeFrom - The internally formatted time by reference timepicker.
         * @returns {string|undefined} - The internally formatted min time evaluated. Undefined if not relevant.
         */
        timePickerMinValue({ dateFrom, dateTo, timeFrom }) {
          const sameDay = dateFrom === dateTo;
          if (sameDay) {
            return timeFrom;
          }
          return undefined;
        },

        /**
         * Triggered when OK button is clicked in timeTo timepicker DIALOG.
         * Used to sync the form but prevent invalid time values as well.
         * @param {object} dlgRef - The ref to timepicker dialog.
         * @param {WorkingHour} wh - The instance of WorkingHour interface.
         */
        onDlgTimeFromSelected(dlgRef, wh) {
          if (dayjs(wh.to, TIME_PICKER_INT_FMT).isBefore(dayjs(wh.from, TIME_PICKER_INT_FMT))) {
            wh.to = wh.from;
          }
          dlgRef.save(wh.from);
        },

        /**
         * Form support method to determine is resource type - a combined one. Similar to server side support methods.
         * @param {string} resType - The resource type.
         */
        isResTypeCombined(resType) {
          return ['C-OR', 'C-AND'].indexOf(resType) >= 0;
        },

        /**
         * Prepares standard structure for translating some field to supported languages.
         * The structure is dictated by REST API, check documentation.
         */
        getTranslFieldsPerLang() {
          return Object.keys(this.languages).reduce((keyMap, langKey) => {
            keyMap[langKey] = '';
            return keyMap;
          }, {});
        },
      },
    });
  },
};
