import { mapGetters } from 'vuex';
// include directive for handling click outside of component
// NOTE: this component cannot be registered as global directive, it is introducing issues with autocomplete.
import vClickOutside from 'v-click-outside';

const { bind, unbind } = vClickOutside.directive;

// NOTE: this one could be extracted to environment so it would be possible to re-build the client code without code changes.
const DEFAULT_TIMEOUT = 8000;

export default {
  inject: ['alertService'],
  data() {
    return {
      snackbar: false,
      timeout: DEFAULT_TIMEOUT,
      msgType: 'error', // by default all results are errors if not proven differently ;)
      msgText: '',

      /**
       * The snackbar element in DOM. This is needed in order to connect v-click-outside plugin programmatically.
       */
      snackEl: null,

      /**
       * The custom timer for displaying the alert snackbar.
       * We need it in order to have a full control of the snack behavior.
       */
      timer: null,

      /**
       * The flag that indicates weather the close button should be displayed.
       */
      showClose: false,
    };
  },
  watch: {
    /**
     * Very important watch to properly bind and unbind click-away.
     * @param {boolean} newVal - The new value of snackbar.
     */
    snackbar(newVal) {
      if (newVal) {
        // using a nextTick in order to wait that snackbar elements are rendered
        this.$nextTick(function myOnNextTick() {
          // this.snackEl = this.$refs.appSnackbar;
          const snack = document.getElementById('app-alert');

          // console.log('next tick binding', this.snackEl);

          // Note: v-click-outside config or handler needs to be passed to the
          //       "bind" function 2nd argument as object with a "value" key:
          //       same as Vue’s directives "binding" format.
          // https://vuejs.org/v2/guide/custom-directive.html#Directive-Hook-Arguments
          if (snack) {
            this.snackEl = snack;
            bind(this.snackEl, { value: this.onClickOutside });
          }
        });
      } else if (this.snackEl) {
        unbind(this.snackEl);
      }
    },
    /**
     * Watch for alertMessage in the store.
     * @param {*} newValue - A new value.
     */
    alertMessage(newValue) {
      if (newValue.msg) {
        this.timeout = DEFAULT_TIMEOUT;
        // determine the type of alert based on message
        this.msgType = newValue.msg.startsWith('message.') ? 'success' : 'error';
        // the message key is translated
        this.msgText = this.$i18n.t(newValue.msg, newValue.msgParams);
        this.snackbar = true;

        // clear previous timer
        clearTimeout(this.timer);

        // create new one
        this.timer = setInterval(() => {
          // close the snack after defined period
          this.closeAlert();
        }, DEFAULT_TIMEOUT);
      } else {
        // unbind(this.snackEl);
        // reset to defaults
        this.snackbar = false;
        this.showClose = false;
        this.msgText = '';
        this.msgType = 'error';
      }
    },
  },
  computed: {
    ...mapGetters(['alertMessage']),
  },
  methods: {
    onAlertClick() {
      // console.log('holding the event prop');
      this.showClose = true;
      this.snackbar = true;
      clearTimeout(this.timer);
    },
    /**
     * Handle click outside of alert..
     */
    onClickOutside() {
      // unbind(this.snackEl);
      // console.log('Alert snackbar: on click outside');
      this.closeAlert();
    },

    closeAlert() {
      this.alertService().clearAlert();
      clearTimeout(this.timer);
    },
  },
};
