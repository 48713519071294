import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify/lib';
// import supported languages for Vuetify
// NOTE: the import of languages for vuetify is now done manually, can be improved similar to dynamic localization of vee validator
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';

// import sr from 'vuetify/es5/locale/sr-Cyrl';
// NOTE: for SRB, forcing the use of custom latin translations
import sr from '../i18n/vuetify-custom-locales/sr-Latn';

// import customized styles
import '../stylus/main.styl';

// import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  lang: {
    locales: { fr, sr, en },
    // t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    // primary: '#729FCF',
    // secondary: '#3465A4',
    // The primary color is used for toolbar and in variants with darker and lighter for other page elements
    // e.g. buttons are using lighter variant
    // NOTE: - the template color is replaced by the one from material palette
    // primary: '#3465A4',
    primary: '#01579b',
    // The complementary color to primary
    pcomplement: '#FFFFFF',

    // we need the theme colors as spare ones when used in combination with custom UI, e.g. footer
    primary_cp: '#01579b',

    // define secondary color better
    secondary: '#729FCF',
    accent: '#b71c1c',
    error: '#ff1c1c',
    // using gray for info color - for now
    info: '#808080',
    // success: '#4CAF50',
    success: '#3465A4',
    warning: '#FF9800',
    // The surface color for the app
    surface: '#FFFFFF',

    // stylus color pack is disabled as experimental try to reduce the bundle size
    // so the used colors in the app added here with their base color from colors.ts
    grey: '#9e9e9e',
    green: '#4caf50',
    red: '#f44336',
    white: '#ffffff',

    //--------
    // defining vars for custom colors
    // for soft deleted items, the gray color is used
    soft_deleted: '#9E9E9E',
    // for order numbers in lists
    ordinal_number: '#9E9E9E',

    // custom colors in agenda/cal
    // slot_free: '#f1f8e9',
    slot_na: '#f5f5f5',
    // slot_e_holiday: '#f3e5f5',

    // The custom grey color used for grey backgrounds in the app, replacement for 'grey'
    // NOTE: the colors are introduced because other non vuetify colors are not available in css!?
    c_grey: '#9e9e9e',
  },
  options: {
    // Enabling customProperties will also generate a css variable for each theme color, which you can then use in your components' <style> blocks.
    // e.g.
    // .something {
    //     color: var(--v-primary-base)
    //     background-color: var(--v-accent-lighten2)
    // }
    customProperties: true,
  },
  iconfont: 'md',
  components: {
    // NOTE: a placeholder for alacarte?
    // VApp,
    // VNavigationDrawer,
    // VFooter,
    // VToolbar,
    // VFadeTransition,
    // VMenu,
  },
});
