import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","persistent":"","fullscreen":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VToolbar,{staticClass:"elevation-2",attrs:{"color":"primary","dense":"","card":""}},[_c(VToolbarTitle,{staticClass:"pcomplement--text"}),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary","flat":"","small":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{staticClass:"pcomplement--text"},[_vm._v("close")])],1)],1),(_vm.docKey)?_c('iframe',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.appDoc(_vm.docKey)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }