// The terms dialog for displaying terms and other app docs.
export default {
  // props: ['docKey'],
  data() {
    return {
      // internal flag that dialog is visible or not
      visible: false,
      /**
       * The name of document key been displayed.
       */
      docKey: null,
    };
  },
  components: {},
  computed: {},
  methods: {
    /**
     * Shows appropriate app doc.
     * @param {string} docKey - The unique key of app document.
     */
    showDoc(docKey) {
      if (docKey) {
        this.docKey = docKey;
        this.visible = true;
      }
    },
    /**
     * Closes the dialog.
     */
    close() {
      this.docKey = null;
      this.visible = false;
    },
  },
};
