/**
 * Common constants across client code.
 *
 * NOTE: some of constants are dynamically built based on environment. Right now this is the common solution for such
 * configuration options - in order to have not so many configuration files.
 *
 * This dynamic build DOES NOT requires any modification of this file, this is done during build process.
 */

// Detect whether the API url is specified from non-web client like electron app
const apiUrl = new URLSearchParams(window.location.search).get('apiUrl');

/**
 * The enumerated employee holiday categories with associated translation keys and icons.
 * Only internal categories are allowed for create/edit through API.
 * @type {Array<object>}
 */
const EMPLOYEE_HOLIDAY_CATEGORIES_INTERNAL = Object.freeze({
  BREAK: {
    key: 'g.holiday.category.break',
    value: 'BREAK',
    icon: 'logout',
  },
  MEETING: {
    key: 'g.holiday.category.meeting',
    value: 'MEETING',
    icon: 'people',
  },
  VACATION: {
    key: 'g.holiday.category.vacation',
    value: 'VACATION',
    icon: 'beach_access',
  },
  SICK_LEAVE: {
    key: 'g.holiday.category.sick_leave',
    value: 'SICK_LEAVE',
    icon: 'local_hospital',
  },
  MAINTENANCE: {
    key: 'g.holiday.category.maintenance',
    value: 'MAINTENANCE',
    icon: 'build',
  },
});

export default {
  /**
   * The authentication token name used as a key for storing auth token in browser storage.
   * @type {string}
   */
  AUTH_TOKEN_NAME: 'twinclicks-authToken',

  /**
   * The date only format used for API calls.
   */
  DATE_FORMAT_API: 'YYYY-MM-DD',

  /**
   * The time only format used for API calls.
   */
  TIME_FORMAT_API: 'HH:mm',

  /**
   * The name of the cookie used in the app.
   * NOTE: the value is picked up from vue-cli environment.
   * @type {string}
   */
  COOKIE_NAME: process.env.VUE_APP_COOKIE_NAME,

  /**
   * The expiration days for a cookie.
   * Right now set for 10 years.
   * NOTE: the value is picked up from vue-cli environment.
   * @type {number}
   */
  COOKIE_EXP_DAYS: +process.env.VUE_APP_COOKIE_EXP_DAYS,

  /**
   * The URL to server from client's application. By default, for production the client is on the same server.
   * However, with webpack/vue-cli the client is not always on the same server, but it can be proxied.
   */
  // SERVER_URL: apiUrl || (process.env.NODE_ENV === 'production' ? '/' : process.env.VUE_APP_SERVER_DEV_URL),
  SERVER_URL: apiUrl || '/',

  /**
   * The list of supported languages with translations (JHI pattern).
   */
  SUPPORTED_LANGUAGES: JSON.parse(process.env.VUE_APP_SUPPORTED_LANGUAGES),

  /**
   * The default sequence of separator characters that are used to display services names in the data as hierarchy and
   * at the same time identify segments for tree-like segments in the UI.
   * @type {string}
   */
  SEPARATOR_CHAR: ' > ',

  /**
   * The enumerated employee holiday categories with associated translation keys and icons.
   * Only internal categories are allowed for create/edit through API.
   * @type {Array<object>}
   */
  EMPLOYEE_HOLIDAY_CATEGORIES_INTERNAL,

  /**
   * All supported employee holiday categories for display purposes including the ones that are read-only.
   */
  EMPLOYEE_HOLIDAY_CATEGORIES: Object.freeze({
    ...EMPLOYEE_HOLIDAY_CATEGORIES_INTERNAL,
    /**
     * The external event like external calendar event that has been merged into holidays.
     */
    EXT_EVENT: {
      key: 'g.holiday.category.ext_event',
      value: 'EXT_EVENT',
      icon: 'event_busy',
    },
  }),

  /**
   * The RFI field types UI support enums.
   */
  RFI_FIELD_TYPES: Object.freeze({
    SINGLE: {
      key: 'g.rfi_field.field_type.single',
      value: 'SINGLE',
    },
    MULTI: {
      key: 'g.rfi_field.field_type.multi',
      value: 'MULTI',
    },
    TEXT: {
      key: 'g.rfi_field.field_type.text',
      value: 'TEXT',
    },
    TEXT_LONG: {
      key: 'g.rfi_field.field_type.text_long',
      value: 'TEXT_LONG',
    },
  }),
};
