import axios from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';

import pickerUtils from '../common/pickerUtils';
/**
 * Prepares Holiday form for saving.
 * NOTE: the same preparation is used for employee holidays with slight differences - keeping separate for possible
 * future changes.
 * @param {object} formDTO - The DTO for managing holidays.
 * @param {string} tz - The standard timezone string.
 */
const prepareHolidayFormForSave = (
  formDTO = {
    id: undefined,
    companyId: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    timeFrom: undefined,
    timeTo: undefined,
    allDay: false,
    remark: '',
  },
  tz,
) => {
  // prepare data for API
  const { dateFrom, dateTo, timeFrom, timeTo, ...dto } = formDTO;

  // console.log('Prepared data', dateFrom, dateTo, timeFrom, timeTo, tz);

  // NOTE: Here we have parsing! and not conversion from timezones
  let dateFromM = dayjs.tz(dateFrom, pickerUtils.DATE_PICKER_INT_FMT, tz);
  let dateToM = dayjs.tz(dateTo, pickerUtils.DATE_PICKER_INT_FMT, tz);

  // if not all day, append hours and minutes
  if (!dto.allDay) {
    const timeFromM = dayjs.tz(timeFrom, pickerUtils.TIME_PICKER_INT_FMT, tz);
    const timeToM = dayjs.tz(timeTo, pickerUtils.TIME_PICKER_INT_FMT, tz);
    dateFromM = dateFromM.hour(timeFromM.hour()).minute(timeFromM.minute());
    dateToM = dateToM.hour(timeToM.hour()).minute(timeToM.minute());
  }

  dto.dateFrom = dateFromM.toISOString();
  dto.dateTo = dateToM.toISOString();

  return dto;
};

/**
 * Prepares Holiday form for edit.
 * @param {object} formDTO - The DTO for managing holidays.
 * @param {string} tz - The standard timezone string.
 */
const prepareHolidayFormForEdit = (
  formDTO = {
    id: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    allDay: false,
    remark: '',
  },
  tz,
) => {
  // prepare from based on data received from API
  const { dateFrom, dateTo, ...dto } = formDTO;

  const dateFromM = dayjs(dateFrom).tz(tz);
  const dateToM = dayjs(dateTo).tz(tz);

  return {
    ...dto,
    timeFrom: dateFromM.format(pickerUtils.TIME_PICKER_INT_FMT),
    timeTo: dateToM.format(pickerUtils.TIME_PICKER_INT_FMT),
    dateFrom: dateFromM.format(pickerUtils.DATE_PICKER_INT_FMT),
    dateTo: dateToM.format(pickerUtils.DATE_PICKER_INT_FMT),
  };
};
/**
 * The client service to support company management.
 */
export default class CompanyManageService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
    // this.init();
  }

  /**
   * Invokes REST API to company with data specified in create/edit form.
   * @param {object} formDTO - The form DTO.
   * @param {object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {string} options.filter.sflt - The scope filter used to for applying scope filter on repository data.
   * @returns {Promise<object>} - The promised updated Employee data.
   */
  async updateCompany(
    formDTO,
    options = {
      filter: {
        sflt: undefined,
      },
    },
  ) {
    // form is already nice and clean, no need to strip data
    // inject company id into the dto
    const dto = {
      ...formDTO,
    };

    const res = await axios.put(
      `api/companies?${queryString.stringify(options.filter, { arrayFormat: 'bracket' })}`,
      dto,
    );
    return res.data;
  }

  /**
   * Loads the set of company's settings.
   * The company is identified by logged user.
   * @param {object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {string} options.filter.sflt - The scope filter used to for applying scope filter on repository data.
   * @returns {Promise<object>} - The promise with object of retrieved settings data.
   */
  async loadSettings(
    options = {
      filter: {
        sflt: undefined,
      },
    },
  ) {
    const companyId = this.evalCompanyId();
    const res = await axios.get(
      `api/companies/${companyId}/settings?${queryString.stringify(options.filter, { arrayFormat: 'bracket' })}`,
    );
    return res.data;
  }

  /**
   * Invokes REST API to persists the general company settings changes.
   * @param {object} companySettingsDTO - The DTO with props to update.
   * @param {object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {string} options.filter.sflt - The scope filter used to for applying scope filter on repository data.
   * @returns {Promise<object>} - The promise with object of retrieved settings data.
   */
  async saveSettings(
    companySettingsDTO,
    options = {
      filter: {
        sflt: undefined,
      },
    },
  ) {
    const id = this.evalCompanyId();

    const res = await axios.patch(
      `api/companies/${id}/settings?${queryString.stringify(options.filter, { arrayFormat: 'bracket' })}`,
      companySettingsDTO,
    );
    return res.data;
  }

  /**
   * The managed company id is evaluated based on authority of logged user.
   * The employee with company admin authority will have company id initialized from the model, while
   * system admin can have this initialized form the route.
   */
  evalCompanyId() {
    return this.router.currentRoute.params.id ? this.router.currentRoute.params.id : this.store.getters.getCompanyId;
  }

  /**
   * Retrieves the list of company holidays.
   * @param {object} filter - The composed filter for the list.
   * @param {string} filter.dateFilterVal - The value of date filter: ACTUAL, PAST.
   * @returns {Promise<[]>} The promise with holidays array.
   */
  async retrieveHolidays(filter) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/holidays?dateFilterVal=${filter.dateFilterVal}`);
    return res.data;
  }

  /**
   * Retrieves the holiday for company.
   * @param {number} holidayId - The holiday serial id.
   * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used. The tz should be used from company's city in this case.
   * @returns {Promise<object>} The promised Holiday data.
   */
  async retrieveHoliday(holidayId, tz = undefined) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/holidays/${holidayId}`);
    return prepareHolidayFormForEdit(res.data, tz);
    // return res.data;
  }

  async createHoliday(formDTO, tz) {
    // inject company id into the dto
    const dto = { ...prepareHolidayFormForSave(formDTO, tz), companyId: this.evalCompanyId() };

    const res = await axios.post(`api/companies/${this.evalCompanyId()}/holidays`, dto);
    return res.data;
  }

  async updateHoliday(formDTO, tz) {
    // inject company id into the dto
    const dto = { companyId: this.evalCompanyId(), ...prepareHolidayFormForSave(formDTO, tz) };

    const { id } = dto;

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/holidays/${id}`, dto);
    return res.data;
  }

  async removeHoliday({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/holidays/${id}`);
    return res.data;
  }

  /**
   * Retrieves the list of company services.
   * @param {object} filter - The composed filter for the list.
   * @param {string} filter.fltDeleted - The value of state filter: DELETED (soft), NOT_DELETED, ALL.
   * @returns {Promise<Array<object>>} The promise with services array.
   */
  async retrieveServices(filter) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/services?${queryString.stringify(filter)}`);
    return res.data;
  }

  /**
   * Retrieves the service (for editing).
   * @param {number} serviceId - The service serial id.
   * @returns {Promise<object>} The promised Service data.
   */
  async retrieveService(serviceId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/services/${serviceId}`);
    const service = res.data;
    // map service into editing form, copy all fields and include some form specific ones
    return {
      ...service,
      // price in the model is expressed in cents so for editing, we need a new field
      // also price can be not specified - in that case the price is not displayed - for consistency
      priceStr: service.price ? service.price / 100 : null,
    };
  }

  async createService(formDTO) {
    // strip not needed form properties
    const { priceStr, priceNum, durationMax, srvType, serviceItems, ...apiDTO } = formDTO;
    // inject company id into the API dto
    const dto = {
      ...apiDTO,
      srvType,
      companyId: this.evalCompanyId(),
      // convert edited price to the cents
      price: Math.round(priceNum * 100),
      // the durationMax is reset to null if 0
      durationMax: srvType === 'SPACING' ? durationMax || null : undefined,
      // force undefined for items if not composite
      serviceItems: srvType === 'COMPOSITE' ? serviceItems : undefined,
    };

    const res = await axios.post(`api/companies/${this.evalCompanyId()}/services`, dto);
    return res.data;
  }

  async updateService(formDTO) {
    // strip not needed form properties
    const { priceStr, priceNum, srvType, durationMax, serviceItems, ...apiDTO } = formDTO;
    // inject company id into the dto
    const dto = {
      ...apiDTO,
      srvType,
      companyId: this.evalCompanyId(),
      // convert edited price to the cents
      price: Math.round(priceNum * 100),
      // the durationMax is reset to null if 0
      durationMax: srvType === 'SPACING' ? durationMax || null : undefined,
      // force undefined for items if not composite
      serviceItems: srvType === 'COMPOSITE' ? serviceItems : undefined,
    };

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/services`, dto);
    return res.data;
  }

  async removeService({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/services/${id}`);
    return res.data;
  }

  async reorderService({ id, newOrdinal } = {}) {
    const res = await axios.post(`api/companies/${this.evalCompanyId()}/services/${id}/reorder?ordinal=${newOrdinal}`);
    return res.data;
  }

  /**
   * Retrieves the list of company working hours.
   * @returns {Promise<[]>} The promise with whs DTO.
   */
  async retrieveWHs() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/whs`);
    return res.data;
  }

  /**
   * Updates all defined WHs at once.
   * @param {object} frmWHs - The form DTO object.
   * @returns {Promise<[]>} The promise with whs DTO.
   */
  async updateWHs(frmWHs) {
    const companyId = this.evalCompanyId();
    const whs = frmWHs.map((w) => {
      const { uid, modals, ...persistWHS } = w;
      return persistWHS;
    });
    // adjust form DTO to REST DTO
    const dto = {
      entityId: companyId,
      whs,
    };
    const res = await axios.put(`api/companies/${companyId}/whs`, dto);
    return res.data;
  }

  /**
   * Imports a company from specified file.
   * @param {File} file - The XLSX file with company import data.
   * @returns {Promise<object>} - The result of imported company.
   */
  async importFromFile(file) {
    const formData = new FormData();

    // NOTE: the pdf_files is a common multipart name for all files
    formData.append('company_import_data', file);

    const res = await axios.post('api/companies-import', formData, {
      /*
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
*/
    });
    return res;
  }

  async removeCompany({ id } = {}) {
    const res = await axios.delete(`api/companies/${id}`);
    return res.data;
  }

  /**
   * Retrieves the list of companies. Logically, this should be available only to SA users.
   * @returns {Promise<[]>} The promise with companies array.
   */
  async retrieveCompanies() {
    const res = await axios.get(`api/companies`);
    return res.data;
  }

  /**
   * Retrieves company employees optionally filtered.
   * @param {object} filter - The composed filter for the list.
   * @param {string} filter.fltDeleted - The value of state filter: DELETED (soft), NOT_DELETED, ALL.
   * @returns {Promise<Array<object>>} - The promised array of company's employees.
   */
  async retrieveEmployees(filter = { fltDeleted: 'NOT_DELETED' }) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/employees?${queryString.stringify(filter)}`);
    return res.data;
  }

  /**
   * Retrieves company employee by specified serial id FOR EDITING.
   * @param {number} employeeId - The employee serial id.
   * @returns {Promise<object>} - The promise with object of retrieved employee.
   */
  async retrieveEmployee(employeeId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/employees/${employeeId}`);
    return res.data;
  }

  /**
   * Invokes REST API to update employee with data specified in create/edit form.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The promised updated Employee data.
   */
  async updateEmployee(formDTO) {
    // form is already nice and clean, no need to strip data
    // inject company id into the dto
    const dto = {
      ...formDTO,
      // prevent nulls for msapi fields
      msapiTenantId: formDTO.msapiTenantId || '',
      msapiUserId: formDTO.msapiUserId || '',
      companyId: this.evalCompanyId(),
    };

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/employees`, dto);
    return res.data;
  }

  /**
   * Invoke REST API for creating employee.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The promised created Employee data.
   */
  async createEmployee(formDTO) {
    // form is already nice and clean, no need to strip data
    // inject company id into the dto
    const dto = {
      ...formDTO,
      // prevent nulls for msapi fields
      msapiTenantId: formDTO.msapiTenantId || '',
      msapiUserId: formDTO.msapiUserId || '',
      companyId: this.evalCompanyId(),
    };

    const res = await axios.post(`api/companies/${this.evalCompanyId()}/employees`, dto);
    return res.data;
  }

  /**
   * Invoke REST API for deleting/soft deleting an employee.
   * @param {number} id - The employee's serial id.
   * @returns {Promise<object>} - The promised delete result object.
   */
  async removeEmployee({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/employees/${id}`);
    return res.data;
  }

  // ====================
  // Content Document management

  /**
   * Retrieves the list of company documents.
   * @returns {Promise<Array<object>>} The promise with content docs array.
   */
  async retrieveCompanyDocs() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/docs`);
    return res.data;
  }

  /**
   * Retrieves the document (for editing).
   * @param {number} docId - The doc serial id.
   * @returns {Promise<object>} The promised doc data.
   */
  async retrieveCompanyDoc(docId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/docs/${docId}`);
    const doc = res.data;
    // map service into editing form, copy all fields and include some form specific ones
    return {
      ...doc,
    };
  }

  /**
   * Retrieves the document file data (for download).
   * @param {object} cdoc - The cdoc DTO in the doc item list.
   * @returns {Promise<object>} The promised doc file data.
   */
  async retrieveCompanyDocFile(cdoc) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/docs-file/${cdoc.id}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
    });

    // now custom download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', cdoc.name); // or any other extension
    document.body.appendChild(link);
    link.click();

    // map service into editing form, copy all fields and include some form specific ones
    // return res.data;
  }

  /**
   * Creates the document.
   * NOTE: for create, when we have an upload - the multi part request is used.
   * @param {object} formDTO - The form DTO.
   * @param {Blob} docFile - The document file blob (File API?).
   * @returns {Promise<object>} - The DTO of created document.
   */
  async createCompanyDoc(formDTO, docFile) {
    const formData = new FormData();

    formData.append('docFile', docFile);

    // strip not needed form properties
    const { langKey, name, description } = formDTO;
    // inject company id into the API dto

    // the name is needed only in case of edit
    if (formDTO.id) {
      formData.append('name', name);
    }
    if (langKey) {
      // do not append langKey if nto specified, in formData it will become string which is not supported lang key
      formData.append('langKey', langKey);
    }
    formData.append('description', description);

    const res = await axios.post(`api/companies/${this.evalCompanyId()}/docs`, formData, {
      /*
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
*/
    });
    return res.data;
  }

  async updateCompanyDoc(formDTO) {
    const { id } = formDTO;
    const dto = {
      ...formDTO,
    };

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/docs/${id}`, dto);
    return res.data;
  }

  async removeCDoc({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/docs/${id}`);
    return res.data;
  }

  // ====================
  // Notes management

  /**
   * Retrieves the list of company notes.
   * @returns {Promise<Array<object>>} The promise with notes array.
   */
  async retrieveCompanyNotes() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/notes`);
    return res.data;
  }

  /**
   * Retrieves the note (for editing).
   * @param {number} noteId - The note serial id.
   * @returns {Promise<object>} The promised note data.
   */
  async retrieveCompanyNote(noteId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/notes/${noteId}`);
    return res.data;
  }

  /**
   * Creates the note.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The DTO of note.
   */
  async createCompanyNote(formDTO) {
    const res = await axios.post(`api/companies/${this.evalCompanyId()}/notes`, formDTO);
    return res.data;
  }

  async updateCompanyNote(formDTO) {
    // strip not needed form properties
    const { id } = formDTO;

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/notes/${id}`, formDTO);
    return res.data;
  }

  async removeCompanyNote({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/notes/${id}`);
    return res.data;
  }

  // ====================
  // Locations management

  /**
   * Retrieves the list of company locations.
   * @returns {Promise<Array<object>>} The promise with locations array.
   */
  async retrieveCompanyLocations() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/locations`);
    return res.data;
  }

  /**
   * Retrieves the Location (for editing).
   * @param {number} locationId - The Location serial id.
   * @returns {Promise<object>} The promised Location data.
   */
  async retrieveCompanyLocation(locationId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/locations/${locationId}`);
    return res.data;
  }

  /**
   * Creates the Location.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The DTO of Location.
   */
  async createCompanyLocation(formDTO) {
    const res = await axios.post(`api/companies/${this.evalCompanyId()}/locations`, formDTO);
    return res.data;
  }

  async updateCompanyLocation(formDTO) {
    // strip not needed form properties
    const { id } = formDTO;

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/locations/${id}`, formDTO);
    return res.data;
  }

  async removeCompanyLocation({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/locations/${id}`);
    return res.data;
  }

  // ====================
  // Breq Filters management

  /**
   * Retrieves the list of company breq filters.
   * @returns {Promise<Array<object>>} The promise with breq filters array.
   */
  async retrieveCompanyBreqFilters() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/breq-filters`);
    return res.data;
  }

  /**
   * Retrieves the breq filter (for editing).
   * @param {number} breqFilterId - The entity serial id.
   * @returns {Promise<object>} The promised note data.
   */
  async retrieveCompanyBreqFilter(breqFilterId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/breq-filters/${breqFilterId}`);
    return res.data;
  }

  /**
   * Creates the breq filter.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The DTO of note.
   */
  async createCompanyBreqFilter(formDTO) {
    const res = await axios.post(`api/companies/${this.evalCompanyId()}/breq-filters`, formDTO);
    return res.data;
  }

  async updateCompanyBreqFilter(formDTO) {
    // strip not needed form properties
    const { id } = formDTO;

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/breq-filters/${id}`, formDTO);
    return res.data;
  }

  async removeCompanyBreqFilter({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/breq-filters/${id}`);
    return res.data;
  }

  // ====================
  // RFI Fields management

  /**
   * Retrieves the list of company RFI fields.
   * @returns {Promise<Array<object>>} The promise with RFI fields array.
   */
  async retrieveCompanyRfiFields() {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/rfi-fields`);
    return res.data;
  }

  /**
   * Retrieves the RFI field (for editing).
   * @param {number} rfiFieldId - The entity serial id.
   * @returns {Promise<object>} The promised note data.
   */
  async retrieveCompanyRfiField(rfiFieldId) {
    const res = await axios.get(`api/companies/${this.evalCompanyId()}/rfi-fields/${rfiFieldId}`);
    return res.data;
  }

  /**
   * Creates the RFI field.
   * @param {object} formDTO - The form DTO.
   * @returns {Promise<object>} - The DTO of note.
   */
  async createCompanyRfiField(formDTO) {
    const res = await axios.post(`api/companies/${this.evalCompanyId()}/rfi-fields`, formDTO);
    return res.data;
  }

  async updateCompanyRfiField(formDTO) {
    // strip not needed form properties
    const { id } = formDTO;

    const res = await axios.put(`api/companies/${this.evalCompanyId()}/rfi-fields/${id}`, formDTO);
    return res.data;
  }

  async removeCompanyRfiField({ id } = {}) {
    const res = await axios.delete(`api/companies/${this.evalCompanyId()}/rfi-fields/${id}`);
    return res.data;
  }

  // ====================
  // Company Transl Fields management
  /**
   * Loads the set of company's translated fields for all saved languages.
   * The company is identified by logged user.
   * @param {object} options - The options for retrieving the transl fields.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {[string]} options.filter.fnames - The names or start chars of the fields to be loaded.
   * @returns {Promise<object>} - The promise with object of retrieved settings data.
   */
  async loadTransl(
    options = {
      filter: {
        fnames: [],
      },
    },
  ) {
    const companyId = this.evalCompanyId();
    const res = await axios.get(
      `api/companies/${companyId}/transl?${queryString.stringify(options.filter, { arrayFormat: 'bracket' })}`,
    );
    return res.data;
  }

  /**
   * Invokes REST API to persists the company translated fields changes.
   * Adjusts the request data to API validation.
   * @param {object} companyTranslMngDTO - The DTO with transl to set/update.
   * @returns {Promise<object>} - The promise with object of retrieved settings data.
   */
  async saveTransl(companyTranslMngDTO) {
    const id = this.evalCompanyId();

    const dto = JSON.parse(JSON.stringify(companyTranslMngDTO));
    // if translations for some languages are not specified, then they should not be sent to API
    // Object.keys(dto).forEach((fname) => {
    //   Object.keys(dto[fname]).forEach((lang) => {
    //     if (!(dto[fname][lang] || '').trim()) {
    //       // = undefined;
    //       delete dto[fname][lang];
    //     }
    //   });
    //   if (!Object.keys(dto[fname]).length) {
    //     delete dto[fname];
    //   }
    // });

    const res = await axios.patch(`api/companies/${id}/transl`, dto);
    return res.data;
  }

  // ====================
  // Company CI management
  /**
   * Loads the one and only CI setting for company.
   * The company is identified by logged user.
   * @returns {Promise<object>} - The promise with object of retrieved CI setting data.
   */
  async loadCISetting() {
    const companyId = this.evalCompanyId();
    const res = await axios.get(`api/companies/${companyId}/ci-settings`);
    return res.data;
  }

  /**
   * Invokes REST API to persists the company CI setting changes.
   * @param {object} ciSettingDTO - The DTO with setting props to create/update.
   * @returns {Promise<object>} - The promise with object of retrieved settings data.
   */
  async saveCISetting(ciSettingDTO) {
    const id = this.evalCompanyId();

    const dto = JSON.parse(JSON.stringify(ciSettingDTO));

    const res = await axios.post(`api/companies/${id}/ci-settings`, dto);
    return res.data;
  }

  /**
   * Invokes REST API to persists the company custom CI active state.
   * @param {boolean} active - To activate use true, to deactivate - false.
   * @returns {Promise<boolean>} - The promise with object of retrieved activation flag.
   */
  async saveCIActivation(active) {
    const id = this.evalCompanyId();

    const dto = { companyId: id, active };

    const res = await axios.post(`api/companies/${id}/ci-settings-activation`, dto);
    return res.data;
  }

  /**
   * Invokes REST API to delete customized CI Setting per company.
   * @returns {Promise<object>} - The delete result of CI Setting delete.
   */
  async deleteCISetting() {
    const id = this.evalCompanyId();

    const res = await axios.delete(`api/companies/${id}/ci-settings`);
    return res.data;
  }

  /**
   * Loads all configured assets for company.
   * The company is identified by logged user.
   * @returns {Promise<object>} - The promise with object of retrieved CI assets data.
   */
  async loadCIAssets() {
    const companyId = this.evalCompanyId();
    const res = await axios.get(`api/companies/${companyId}/ci-assets`);
    return res.data;
  }

  /**
   * Creates new company CI asset.
   * NOTE: when we have an upload - the multi part request is used.
   * @param {object} formDTO - The form DTO.
   * @param {Blob} assetFile - The CI asset file blob (File API?).
   * @returns {Promise<object>} - The DTO of created CI asset.
   */
  async createCIAsset(formDTO, assetFile) {
    const formData = new FormData();

    formData.append('assetFile', assetFile);

    // strip not needed form properties
    const { name } = formDTO;

    // the name is appended as part of the form (possible modification by CA in form)
    formData.append('name', name);
    // The logo is right now one and only asset, so forcing it.
    formData.append('assetType', 'LOGO');

    const res = await axios.post(`api/companies/${this.evalCompanyId()}/ci-assets`, formData, {
      /*
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
*/
    });
    return res.data;
  }

  /**
   * Invokes REST API to delete CI asset per company.
   * @param {string} assetType - The enumerated asset type.
   * @returns {Promise<object>} - The delete result of CI Asset delete.
   */
  async deleteCIAsset(assetType) {
    const id = this.evalCompanyId();

    const res = await axios.delete(`api/companies/${id}/ci-assets/${assetType}`);
    return res.data;
  }
}
