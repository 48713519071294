import { mapGetters } from 'vuex';
import constants from '../common/constants';

const { SERVER_URL } = constants;

export default {
  inject: ['translationService', 'accountService', 'companyService', 'supportReq'],
  components: {},
  data() {
    return {
      /**
       * The company might be needed for displaying some info about the company.
       */
      company: null,
      /**
       * Options for About modal dialog including flag to hide it.
       */
      aboutDialogOptions: {
        text: this.$t('label.no_version_info'),
        show: false,
      },
      /**
       * Options for About modal dialog including flag to hide it.
       */
      licDlgOptions: {
        show: false,
      },
      showMenu: false,
      /**
       * The flag that indicates that the header is in back mode, the back button arrow should be present with
       * optional reduced content of the header.
       *
       * NOTE: this flag depends on route, thats why its not initialized as computed prop but on route changes in watch.
       */
      backMode: false,
    };
  },
  watch: {
    // $route(to, from) {
    $route(to) {
      // The back button will not be available if it is explicitly hidden for not signed in accounts for particular page
      this.backMode = to.meta.backButton === true && !((to.meta.hideBBForNoAccount && !this.account) || this.isKiosk);
    },
  },
  created() {},
  computed: {
    ...mapGetters([
      'authenticated',
      'getAuthorities',
      'getEmployeeId',
      'getCompanyId',
      'isCA',
      'isEmployee',
      'isKiosk',
      'account',
      'currentLanguage',
      'languages',
      'fullVersion',
      'versionNote',
      'progress',
      'sysInfo',
      'uiCI',
    ]),

    /**
     * The company identity is identified in all possible ways: employee, query param, route path.
     * @type {number}
     */
    companyId() {
      // NOTE: the context companyId is identified through resource user or route query param. Here the $route looks reactive, similar for account.
      return this.account?.companyId || this.$route.query.companyId;
    },

    isCustomUICI() {
      return this.uiCI.ciEnabled;
    },

    /**
     * The logo will NOT be displayed if CI is enabled and logo not specified.
     * @returns {boolean} - True of the logo should be shown.
     */
    showLogo() {
      if (this.uiCI.ciEnabled && !this.uiCI.hasLogo) {
        return false;
      }
      return true;
    },

    /**
     * The path to logo asset of the company in current UI context.
     * @returns {string} - The path to asset over API.
     */
    appLogoPath() {
      const companyIdQuery = this.uiCI.companyId ? `&companyId=${this.uiCI.companyId}` : '';
      const logoPath = `${SERVER_URL}api/system/assets?assetType=LOGO${companyIdQuery}&ts=${this.uiCI.ts}`;
      return logoPath;
    },

    appHeader() {
      // in case of employee, always use CI setting values even when they are the default
      if (this.getEmployeeId) {
        return this.uiCI.ciEnabled ? this.uiCI.headline : this.$t('label.application_name');
      }
      return this.uiCI.ciEnabled ? this.uiCI.headline : this.$t('label.application_name');
    },

    /**
     * Whether the UI should have the elements of the QMS operating post.
     */
    isOpPost() {
      return this.isEmployee && this.account.employee.company.qmsAdvanced;
    },
    isScreensAvailable() {
      return this.isEmployee && this.account.employee.company.bpTickets;
    },
    /**
     * NOTE: the menu items is reactive because it might rely on some reactive data.
     */
    items() {
      return [
        {
          title: this.$t('menu.item.system_settings'),
          link: '/system',
          authorization: ['SA'],
          icon: 'settings',
        },
        {
          title: this.$t('menu.item.user_list'),
          link: '/users',
          authorization: ['SA'],
          icon: 'people_outline',
        },
        {
          title: this.$t('menu.item.company_import'),
          link: '/companies-import',
          authorization: ['SA'],
          icon: 'cases',
        },
        ...(this.isOpPost
          ? [
              {
                // only employee can access QMS management
                title: this.$t('menu.item.qms_operating_post'),
                link: `/qms-operating-post`,
                authorization: ['EMPLOYEE'],
                icon: 'dvr',
              },
            ]
          : []),
        {
          // only employee can access bookings
          title: this.$t('menu.item.agenda'),
          link: `/agenda?employeeId=${this.getEmployeeId}`,
          authorization: ['EMPLOYEE'],
          icon: 'schedule',
        },
        {
          // only employee can access company orders and search them
          title: this.$t('menu.item.orders_search'),
          link: `/orders`,
          authorization: ['EMPLOYEE'],
          icon: 'search',
        },
        ...(this.isScreensAvailable
          ? [
              {
                title: this.$t('menu.item.screens'),
                link: `/screens`,
                authorization: ['EMPLOYEE', 'CA'],
                icon: 'tv',
              },
            ]
          : []),
        // {
        //   // only employee can access bookings
        //   title: this.$t('Calendar'),
        //   link: `/agenda-cal?employeeId=${this.getEmployeeId}`,
        //   authorization: ['EMPLOYEE'],
        //   icon: 'calendar_view_day',
        // },
        {
          title: this.$t('menu.item.resource_holidays'),
          link: `/employee-management/${this.getEmployeeId}/holidays`,
          authorization: ['EMPLOYEE'],
          icon: 'beach_access',
        },
        {
          title: this.$t('menu.item.client_bookings'),
          link: `/home/favorites`,
          authorization: ['USER'],
          icon: 'calendar_today',
          // icon: 'book',
        },
        {
          // only CA has separator because of extra actions
          separator: true,
          // authorization: ['CA'],
        },
        {
          title: this.$t('g.button.contact_form'),
          link: '/contact-form',
          icon: 'mail_outline',
        },
        {
          title: this.$t('g.button.support_request'),
          action: { name: 'supportReq' },
          authorization: ['CA', 'EMPLOYEE'],
          // The optional class used for menu item
          // class: 'font-weight-bold',
          icon: 'phone',
        },
        {
          // only CA has separator because of extra actions
          separator: true,
          authorization: ['CA'],
        },
        {
          title: this.$t('menu.item.company_settings'),
          link: '/company-management',
          authorization: ['CA'],
          icon: 'settings',
        },
        {
          title: this.$t('menu.item.license'),
          action: { name: 'showLicModal' },
          authorization: ['CA'],
          icon: 'ballot',
        },
        {
          separator: true,
        },
        {
          title: this.$t('menu.item.edit_profile'),
          link: '/profile',
          icon: 'account_box',
        },
        {
          separator: true,
        },
        {
          title: this.$t('menu.item.about'),
          action: { name: 'showAboutModal' },
          icon: 'info',
        },
        {
          separator: true,
        },
        {
          title: this.$t('menu.item.logout'),
          action: { name: 'logout' },
          icon: 'meeting_room',
        },
      ];
    },
  },
  methods: {
    async changeLanguage(newLanguage) {
      await this.translationService().refreshTranslation(newLanguage);
    },

    logout() {
      this.accountService().doLogout();
      // this.logoutUser();
      // this.$router.push('/login');
    },

    showAboutModal() {
      this.aboutDialogOptions.show = true;
    },

    async showLicModal() {
      if (this.isCA) {
        this.company = await this.companyService().retrieveCompany(this.getCompanyId, {
          filter: { sflt: 'mngSys' },
        });
        this.licDlgOptions.show = true;
      }
    },

    /**
     * Performs action according to configuration: some router action or router link.
     * @param {object} item - The menu item.
     */
    doAction(item) {
      // in case of link, go to router view
      if (item.link) {
        this.$router.push(item.link);
      } else {
        // execute action otherwise
        this[item.action.name](item.action.payload);
      }
    },

    /**
     * Evaluate should menu item be displayed or not according to authority.
     * @param {object} item - The menu item iterated in loop.
     * @returns {boolean} True if the item should be shown.
     */
    showMenuItem(item) {
      const authorization =
        !item.authorization ||
        (item.authorization && item.authorization.some((auth) => this.getAuthorities.indexOf(auth) >= 0));
      /* The alternative solution with jhipster:
          this.$store.getters['account'] &&
          item.authorization.some((auth) => this.$store.getters['account.authorities'].indexOf(auth) >= 0));
*/

      return authorization;
    },
    /**
     * Evaluates the translation key for a back button based on meta data in the route.
     * If not found, returns the default.
     */
    backButtonKey() {
      if (this.$route.meta && this.$route.meta.backButtonKey) {
        return this.$route.meta.backButtonKey;
      }
      return 'g.button.back';
    },
  },
};
