import axios from 'axios';

/**
 * The service to support client-specific logic.
 */
export default class ClientService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
  }

  /**
   * Loads information about client's favorite companies for displaying purposes.
   *  @returns {Promise<[]<object>>} - The promise with object of retrieved favorite companies.
   */
  async retrieveFavoriteCompanies() {
    const res = await axios.get(`api/client/favorite-companies`);
    return res.data;
  }

  /**
   * Loads the list of all clients companies used in ALL orders.
   *  @returns {Promise<[]<object>>} - The promise with object of retrieved companies.
   */
  async retrieveAllOrdersCompanies() {
    const res = await axios.get(`api/client/orders-companies`);
    return res.data;
  }

  /**
   * Adds a company to the set of client's favorite companies.
   * @param {number} companyId - The company serial id.
   *  @returns {Promise<object>} - The promise with object of created favorite company result.
   */
  async addToFavoriteCompanies(companyId) {
    const res = await axios.post(`api/client/favorite-companies`, { companyId });
    return res.data;
  }

  /**
   * Removes a company from the set of client's favorite companies.
   * @param {number} companyId - The company serial id.
   *  @returns {Promise<object>} - The promise with object representing removal info.
   */
  async removeFromFavoriteCompanies(companyId) {
    const res = await axios.delete(`api/client/favorite-companies/${companyId}`);
    return res.data;
  }

  /**
   * Lookups for companies available on system.
   * @param {object} filter - The search filter.
   * @param {string} filter.countryCode - The country code is mandatory for search.
   * @param {string} filter.searchKey - The search key.
   * @param {string} filter.activity - The search by activity enumeration.
   * @param {string} filter.cityName - The search by explicit internal city code.
   * @returns {Promise<[]<object>>} - The promise with object of found companies DTOs.
   */
  async findCompanies({ countryCode, searchKey = '', activity = '', cityName = '' }) {
    const res = await axios.get(
      `api/client/companies?countryCode=${countryCode}&cityName=${cityName}&activity=${activity}&searchKey=${searchKey}`,
    );
    return res.data;
  }
}
