import axios from 'axios';
import cookieUtils from '../common/customCookieUtils';
import constants from '../common/constants';

const { AUTH_TOKEN_NAME } = constants;

/**
 * The home paths based on user's authority.
 * @type {{EMPLOYEE: string, USER: string, SA: string}}
 */
const HOME_ROUTES = Object.freeze({
  // SA: '/user',
  SA: '/companies-import',
  // CA: '/company-management',
  KIOSK: '/b',
  EMPLOYEE: '/agenda',
  USER: '/home/favorites',
});

/**
 * The array of not available pages when already signed in.
 * @type {string[]}
 */
const NOT_AVAILABLE_PAGES_WHEN_SIGNED_IN = Object.freeze(['loginPage']);

/**
 * The router names of the pages for which (when accessed - to) the CI should be reset ALWAYS.
 * @type {Readonly<string[]>}
 */
// const RESET_CI_TO_PAGES = Object.freeze(['loginPage' /*, 'clientLandingPage'*/]);

/**
 * The router names of the pages for which (when accessed - to) the CI should be set ALWAYS.
 * @type {Readonly<string[]>}
 */
const SWITCH_TO_CI_PAGES = Object.freeze(['bookingRequestPage', 'bookingRequestEditPage', 'orderDetails']);

/**
 * Some router specific common cleanups.
 */
function contentCleanup() {
  const printEl = document.getElementById('kiosk_ticket_print');
  printEl.innerHTML = '';
}

/**
 * The function used with timer to periodically refresh the token.
 * @returns {Promise<void>} - The empty promise.
 */
async function refreshAuthToken() {
  const rememberUser = localStorage.getItem('rememberUser') === 'true';
  const res = await axios.post('api/auth/refresh', { sso: rememberUser });

  const token = res.data.id_token;
  if (token) {
    if (rememberUser) {
      localStorage.setItem(AUTH_TOKEN_NAME, token);
    } else {
      sessionStorage.setItem(AUTH_TOKEN_NAME, token);
    }
    console.log('TR');
  }
}

export default class AccountService {
  constructor({ store, router, alertService = undefined, translationService = undefined }) {
    this.store = store;
    this.translationService = translationService;
    this.alertService = alertService;
    this.router = router;
    /**
     * The from route is used to remember the vue router 'from' route, since we have a router configured in this class.
     * @type {object | null}
     */
    this.fromRoute = null;
    // this.init();
    /**
     * The auth token refresh timer.
     * @type {object | null}
     */
    this.trTimer = null;

    /**
     * Custom handler function when the application tab is selected - related to the token refresh.
     */
    this.onVisibilityChange = () => {
      console.log('visi change');
      if (document.visibilityState === 'visible') {
        // backgroundMusic.play();
        console.log('TR on vis');
        refreshAuthToken();
      } else {
        // no action when becomes hidden
        // backgroundMusic.pause();
      }
    };

    console.log('account service instance created');
  }

  /**
   * Starts the refresh timer.
   */
  startTrTimer() {
    // register visibility event to also refresh the token
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    const interval = this.store.getters.sysInfo.ctri * 1000;
    console.log('TR in...', interval);
    // start the timer and keep the reference to it
    this.trTimer = setInterval(refreshAuthToken, interval);
  }

  killTrTimer() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    clearInterval(this.trTimer);
    this.trTimer = null;
  }

  /**
   * Trigger initialization of account service asynchronously.
   */
  async init() {
    // NOTE: TC CUSTOM: Right now the app is using cookie instead of token for storing authorization. CR for switch to JHI created.
    const token = localStorage.getItem(AUTH_TOKEN_NAME) || sessionStorage.getItem(AUTH_TOKEN_NAME);
    // const token = cookieUtils.getCookie(constants.COOKIE_NAME);
    // NOTE: deleting possibly obsolete cookie
    cookieUtils.deleteCookie(constants.COOKIE_NAME);
    if (!this.store.getters.account && !this.store.getters.logon && token) {
      // Using cookieless solution, remember the token into the corresponding store if the store is initialized that way
      if (localStorage.getItem('rememberUser') === 'true') {
        localStorage.setItem(AUTH_TOKEN_NAME, token);
      }
      // IMPORTANT:
      // if retrieve account fails during initialization, handle this error exclusively without the use of error handler
      try {
        await this.retrieveAccount();
        // if there is a token on app start, start refresh interval
        // NOTE: token refresh used only for SSO? Now in any case.
        if (this.authToken /* && this.rememberUser */) {
          this.startTrTimer();
        }
      } catch (e) {
        // translations needed if not loaded, not present at all in the client app
        await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
        // show alert with some delay
        setTimeout(() => {
          this.alertService.showAlert(e.message);
        }, 500);
        // perform logout logic
        this.doLogout();
      }
    } else {
      // initialize translations if account is not logged in
      await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
    }
    // router after all account data are available
    this.initRouter();
  }

  /**
   * Initializes router hooks and security. The method should bee used after account is retrieved.
   */
  initRouter() {
    this.router.beforeEach((to, from, next) => {
      // TC CUSTOM: used in system mixin, do not delete!
      this.fromRoute = from;

      // NOTE: pre-processing:
      // reset or set CI depending on authorityh and pages
      if (to.name !== from.name) {
        if (this.account?.employee) {
          // in case of company resource, the CI setup should be applied only upon login. In other cases, the F5 is needed
          // when some CA changes something and other resources have already open apps.
          if (from.name === 'loginPage') {
            this.store.commit('setUICI', this.account?.employee?.company.ci);
          }
        } else if (SWITCH_TO_CI_PAGES.indexOf(to.name) < 0) {
          this.store.commit('setUICI');
        }
      }

      // TC CUSTOM: if the requested route is / that is dynamic, evaluate it based on authority
      if (to.path === '/') {
        next(this.homePageByAuth());
        return;
      }
      if (!to.matched.length) {
        // NOTE: TC CUSTOM: we are using home page / login instead of not-found for now
        // next('/not-found');
        next(this.homePageByAuth());
        return;
      }

      // in case of already signed in user, prevent going to not allowed pages when signed in
      if (this.account && NOT_AVAILABLE_PAGES_WHEN_SIGNED_IN.indexOf(to.name) >= 0) {
        next(this.homePageByAuth());
        return;
      }

      // in case when advanced QMS is disabled, the qms op post should not be available
      if (
        to.name === 'qmsOperatingPostPage' &&
        this.account &&
        this.account.employee &&
        !this.account.employee.company.qmsAdvanced
      ) {
        next(this.homePageByAuth());
        return;
      }

      // some common html cleanup
      if (this.store.getters.isKiosk) {
        // clear html content
        contentCleanup();
        // proceed further...
      }

      if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
        if (!this.hasAnyAuthority(to.meta.authorities)) {
          sessionStorage.setItem('requested-url', to.fullPath);
          console.log('ROUTER: BEFORE EACH - FORBIDDEN path', to.fullPath);
          // NOTE: TC CUSTOM: we are using login instead of forbidden for now
          // next('/forbidden');
          next(this.homePageByAuth());
        } else {
          // there is an authority, so proceed
          next();
        }
      } else {
        // no authorities, so just proceed
        next();
      }
    });
  }

  /**
   * Evaluates home page by authority.
   * TC CUSTOM.
   */
  homePageByAuth() {
    // find first in the list and go there
    const userAuthorities = this.store.getters.getAuthorities;
    const userAuth = Object.keys(HOME_ROUTES).find((authority) => userAuthorities.indexOf(authority) >= 0);
    if (userAuth) {
      const route = HOME_ROUTES[userAuth];
      if (route === '/agenda') {
        const { isCA } = this.store.getters;
        // override agenda route if TAKEOVER QMS is enabled
        const { account } = this.store.getters;
        const { employee } = account;
        // if pure employee and QMS takeover, go to qms operating post
        if (!isCA && employee.company && employee.company.qmsAdvanced && employee.company.qmsResMngMode) {
          return `/qms-operating-post`;
        }
        return `${route}?employeeId=${this.store.getters.getEmployeeId}`;
      }
      return route;
    }
    // NOTE: if not authenticated - the only available page is login
    return '/login';
  }

  /**
   * Register user.
   * @param {object} accountData - The account registration data.
   */
  async register(accountData) {
    return axios.post('api/register', accountData);
  }

  /**
   * Activate register user.
   * @param {object} key - The the account activation key.
   */
  async activate(key) {
    try {
      await axios.get(`api/activate?key=${key}`);
      // after the successful activation, perform login data cleanup which will cleanup any previous logins
      cookieUtils.deleteCookie(constants.COOKIE_NAME);
      this.store.commit('logout');
      return 'success';
    } catch (e) {
      return 'error';
    }
  }

  /**
   * Initiates reset password request and prepares results for display.
   * @param {string} email - The email used to reset the password.
   * @returns {Promise<object>} - The simple DTO with success flag and message key as representation of result.
   */
  async requestPasswordReset(email) {
    try {
      await axios.post(
        'api/account/reset-password/init',
        { email },
        {
          headers: {
            // 'content-type': 'text/plain',
            // NOTE: TC CUSTOM: It is still possible to initiate and finish password reset even while the user is still logged in
            // In order to do this, the user would need to type in the reset ini URL in browser, which is a rare case.
            // The only difference in UX is that user would still be in the app and not on login page after resetting password.
            // In order to force pass logout with this action, we need would to clear authorization token probably. The requested URL is not protected anyhow.
          },
        },
      );
      // no exceptions - success
      return { success: true, msgKey: 'reset_password.info.check_emails' };
    } catch (e) {
      return { success: false, msgKey: e.message };
    }
  }

  /**
   * Finish password reset.
   * @param {string} newPassword - The new password.
   * @param {string} key - The unique reset key.
   * @returns {Promise<{success: boolean, msgKey: string}>} - The result dto.
   */
  async finishPasswordReset(newPassword, key) {
    try {
      await axios.post(
        'api/account/reset-password/finish',
        { newPassword, key },
        {
          headers: {
            // NOTE: TC CUSTOM: same as for init
          },
        },
      );
      // no exceptions - success
      return { success: true, msgKey: 'reset_password_finish.info.success' };
    } catch (e) {
      return { success: false, msgKey: e.message };
    }
  }

  /**
   * Initiate the login for login page.
   * The JHI pattern logic.
   * NOTE: in case of some http error, the error needs to be catched on invoker page.
   * @param {object} dto - The login form DTO.
   * @returns {Promise<void>} - The promise.
   */
  async doLogin(dto) {
    const result = await axios.post('api/authenticate', dto);
    // NOTE: the bearer token is received over body but also within response header (JHI pattern that follows oauth specs?)
    const bearerToken = result.headers.authorization;
    // const bearerToken = result.data.token;
    // console.log('AUTHENTICATE: bearer token', bearerToken, result.headers);
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      // if (bearerToken) {
      const jwt = bearerToken.slice(7, bearerToken.length);
      // const jwt = bearerToken;
      if (dto.rememberUser) {
        // cookieUtils.setCookie(constants.COOKIE_NAME, jwt, constants.COOKIE_EXP_DAYS);
        // set flag in the local storage that user has logged in with sso flag
        localStorage.setItem('rememberUser', 'true');
        // JHI pattern: remember the auth token in local storage as future alternative to the cookie
        localStorage.setItem(AUTH_TOKEN_NAME, jwt);
      } else {
        // cookieUtils.setCookie(constants.COOKIE_NAME, jwt);
        // JHI pattern: remember the auth token in session storage as future alternative to the session cookie
        sessionStorage.setItem(AUTH_TOKEN_NAME, jwt);
      }
      // initialize auth token refresh timer
      // NOTE: token refresh used only in SSO
      this.startTrTimer();
    }
    // try to load account data
    await this.retrieveAccount();
  }

  doLogout() {
    this.killTrTimer();
    // The obsolete logic to delete old cookies not used anymore
    cookieUtils.deleteCookie(constants.COOKIE_NAME);
    // delete the login type from local storage - always on logout
    localStorage.removeItem('rememberUser');
    // JHI pattern: as future alternative to the cookie, delete the auth token from local and session store
    localStorage.removeItem(AUTH_TOKEN_NAME);
    sessionStorage.removeItem(AUTH_TOKEN_NAME);
    // clear requested url
    sessionStorage.removeItem('requested-url');
    this.store.commit('logout');
    // this.router.push('/');
    this.router.replace({ name: 'loginPage' });
  }

  /**
   * Redirects to EA page.
   * @param {string} status -The string status for EA page, check the page logic for details.
   */
  goToEAPage(status) {
    this.router.replace({
      name: 'ea',
      params: {
        ctx: 'status', // the status mode
        status, // the status value
      },
    });
  }

  /**
   * Redirects to fromRoute in case of entity not found error (check error handler for details).
   */
  goBackOnNotFound() {
    if (this.fromRoute) {
      this.router.replace({ ...this.fromRoute });
    }
  }

  /**
   * Entirely deletes account from the system on account's request.
   */
  async deleteAccount() {
    await axios.delete('api/account');
    this.doLogout();
  }

  /**
   * Retrieves the flags about the existence of an account identified by email.
   * If the email is not valid by REST API standards, the false value will be returned for all flags.
   * @param {string} email - The email.
   * @returns {Promise<{ e, c, cc }>} - Flags about account existence.
   */
  async retrieveAccountExistence(email) {
    try {
      const res = await axios.get(`api/ui/${email}`);
      return res.data;
    } catch (e) {
      console.warn('Unable to get uinfo, defaulting to all false flags', e.message);
    }
    return { e: false, c: false, cc: false };
  }

  /**
   * Retrieves account full info from the server and initializes corresponding store data and other resources.
   */
  async retrieveAccount() {
    this.store.commit('authenticate');
    const response = await axios.get('api/account');
    const account = response.data;
    if (account) {
      this.store.commit('authenticated', account);

      if (this.store.getters.currentLanguage !== account.langKey) {
        this.store.commit('currentLanguage', account.langKey);
      }
      // if some URL was requested, try to use it
      // if (sessionStorage.getItem('requested-url')) {
      //   this.router.replace(sessionStorage.getItem('requested-url'));
      //   sessionStorage.removeItem('requested-url');
      // } else if (this.router.currentRoute.path === '/login') {
      //   // otherwise resolve home page by authorities - if we are coming from login
      //   this.router.push(this.homePageByAuth());
      // }
    } else {
      // sessionStorage.removeItem('requested-url');
      this.doLogout();
    }
    await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
  }

  /**
   * Returns true if user has any of specified authorities.
   * NOTE: this method is actually not used, instead the mixin is available for use on pages.
   * @param {string|Array<string>} authoritiesParam - The authority or array of authorities names.
   * @returns {boolean} - True of has any of specified authorities.
   */
  hasAnyAuthority(authoritiesParam) {
    let authorities = authoritiesParam;
    if (typeof authorities === 'string') {
      authorities = [authoritiesParam];
    }
    if (!this.authenticated || !this.userAuthorities) {
      return false;
    }

    for (let i = 0; i < authorities.length; i += 1) {
      if (this.userAuthorities.includes(authorities[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Retrieves ONLY account's profile data for editing.
   * @returns {Promise<object>} - The promise with profile DTO.
   */
  async retrieveProfile() {
    const res = await axios.get('api/account/profile');
    return res.data;
  }

  /**
   * Retrieves ONLY account's profile data for editing.
   * @param {object} formDTO - The DTO with all profile form data.
   * @returns {Promise<object>} - The promise with profile DTO.
   */
  async saveProfile(formDTO) {
    // append to request the current state of rememberUser store variable
    const rememberUser = localStorage.getItem('rememberUser') === 'true';
    const dto = { ...formDTO, rememberUser };
    const res = await axios.put('api/account/profile', dto);
    // if new token is returned (token is changed because of modification of some token related fields) store a new token in appropriate place
    if (res.data.newTkn) {
      if (rememberUser) {
        // cookieUtils.setCookie(constants.COOKIE_NAME, res.data.newTkn, constants.COOKIE_EXP_DAYS);
        // Store in browser store as new alternative to the cookies
        localStorage.setItem(AUTH_TOKEN_NAME, res.data.newTkn);
      } else {
        // cookieUtils.setCookie(constants.COOKIE_NAME, res.data.newTkn);
        sessionStorage.setItem(AUTH_TOKEN_NAME, res.data.newTkn);
      }
    }
    // after updating, refresh the account in the store
    await this.retrieveAccount();
    return res.data;
  }

  /**
   * Saves preferences for currently used device and also updates them in the store.
   * @param {object} prefsDTO - The per device preferences DTO.
   * @returns {Promise<object>} - The promise with updated prefs.
   */
  async saveDevicePrefs(prefsDTO) {
    const res = await axios.post('api/account/device-prefs', prefsDTO);
    const prefs = res.data;
    // update the device prefs in the store too, the API is returning the integral version of the prefs!
    this.store.commit('devicePrefs', prefs);

    return prefs;
  }

  get authenticated() {
    return this.store.getters.authenticated;
  }

  get userAuthorities() {
    return this.store.getters.account.authorities;
  }

  get account() {
    return this.store.getters.account;
  }

  get authToken() {
    return localStorage.getItem(AUTH_TOKEN_NAME) || sessionStorage.getItem(AUTH_TOKEN_NAME);
  }

  /**
   * Custom getter to get the remember user flag from the local storage.
   * @returns {boolean} - True if the user has selected to remember the login for sso.
   */
  get rememberUser() {
    return localStorage.getItem('rememberUser') === 'true';
  }

  /**
   * Sets the remember user - sso flag to local storage.
   * @param {boolean} sso - The flag to store to local storage.
   */
  set rememberUser(sso) {
    localStorage.setItem('rememberUser', sso ? 'true' : 'false');
  }
}
