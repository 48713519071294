import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VToolbar,{staticClass:"elevation-2",attrs:{"color":"primary","dense":"","card":""}},[_c(VToolbarTitle,{staticClass:"pcomplement--text"},[_vm._v(_vm._s(_vm.$t('c.leave_page_confirm_dialog.title')))])],1),_c(VCardText,{domProps:{"textContent":_vm._s(_vm.$t('c.leave_page_confirm_dialog.message'))}}),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.onAnswer(false)}}},[_vm._v(_vm._s(_vm.$t('c.leave_page_confirm_dialog.button.stay')))]),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"primary pcomplement--text"},on:{"click":function($event){return _vm.onAnswer(true)}}},[_vm._v(" "+_vm._s(_vm.$t('c.leave_page_confirm_dialog.button.leave'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }