import dayjs from 'dayjs';

/**
 * Helper method to prepare properly the dayjs instance out of provided date.
 * NOTE: This function is dealing currently ONLY with Date, not designed for parsing a date from tz.
 * @param {Date} date - The date which will be used for creating an instance of dayjs.
 * @param {string} locale - The locale supported by app.
 * @param {string?} tz - The timezone in standard format supported by dayjs, e.g. Europe/London, Europe/Zurich.
 * If tz is not specified, the local timezone will be guesses by dayjs.
 * @returns {dayjs.Dayjs} - The prepared instance of dayjs according to specified params.
 */
function toLocalizedTZ(date, locale, tz = undefined) {
  return tz ? dayjs(date).locale(locale).tz(tz) : dayjs(date).locale(locale);
}

/**
 * Evaluates locale code based on ISO country code and language key with optional overrides.
 * @param {{countryCode:string, langKey:string, overrides:boolean}} params - The params. If overrides flag is true,
 * the locale code will include custom mappings used in this app, eg for currency purposes.
 * @returns {string} - The prepared locale with language and country code.
 */
function localeCode({ countryCode, langKey, overrides = false }) {
  // NOTE: country code in the model is now 3 chars, we need to strip it for regional code to 2 chars.
  const countryCodeShort = countryCode.slice(0, 2);
  let langCodeReg = langKey;
  if (overrides) {
    if (countryCode === 'CHE') {
      // Override for CH because thousand separator is not appropriate for fr language (at least we think)
      langCodeReg = 'en';
    }
  }
  return `${langCodeReg}-${countryCodeShort}`;
}

/**
 * Pure common application custom formatter methods that can be used in Vue filters or mixins.
 *
 * IMPORTANT NOTE: IN order to avoid the dayjs issue with UTC-ish timezones like Europe/Dublin, for formatting
 * function it is required to first set the locale and then perform formatting.
 * @module common/customFormatters
 */
export default {
  /*
   * Exported utility functions.
   */
  toLocalizedTZ,
  localeCode,

  /**
   * Formats date as time only - localized.
   * @param {Date} value - The date to format.
   * @param {string} locale - The user's locale.
   * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
   */
  fmtTimeOnlyLocalized(value, locale, tz = undefined) {
    return toLocalizedTZ(value, locale, tz).format('LT');
  },

  /**
   * Formats the date time in a nice human readable way.
   * @param {Date} value - The date/time.
   * @param {string} locale - The user's locale.
   * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
   */
  fmtDateTimeHuman(value, locale, tz = undefined) {
    // fallback, if the value is omitted, return empty string
    if (!value) {
      return '';
    }
    // NOTE: in order to support proper date formatting for some languages, the day of month will have variants depending on locale
    // NOTE: there is an issue #1140 to improve formatting according to grammar.
    let domFormat = 'D';
    if (locale === 'sr') {
      domFormat = 'D.';
    }

    return toLocalizedTZ(value, locale, tz).format(`dddd, ${domFormat} MMMM YYYY, LT`);
  },

  /**
   * Formats the date in human readable form.
   * @param {Date} value - The Date value to format.
   * @param {string} locale - The locale key to use for formatting.
   * @param {string|undefined} tz - The timezone string. If not specified, the locale tz will be used.
   * @returns {string} The formatted Date.
   */
  fmtDateHuman(value, locale, tz) {
    // fallback, if the value is omitted, return empty string
    if (!value) {
      return '';
    }

    // NOTE: in order to support proper date formatting for some languages, the day of month will have variants depending on locale
    // NOTE: there is an issue #1140 to improve formatting according to grammar.
    let domFormat = 'D';
    if (locale === 'sr') {
      domFormat = 'D.';
    }
    return toLocalizedTZ(value, locale, tz).format(`dddd, ${domFormat} MMMM YYYY`);
  },

  /**
   * Divides with 100 and formats the service price (expressed in cents in the model) in the country defined locale, or detected locale.
   * @param {number} price - The price expressed in cents.
   * @param {object} options - The options about how to perform formatting.
   * @param {string} options.currency - The currency code to include in formatted price, if not specified it will be EMPTY.
   * @param {object | undefined} options.country - The country of the user. This is used as a reference about how to format numbers.
   * @param {string} options.language - The language of the user using the app.
   * @returns {string} - The formatted and properly calculated price in country locale or detected locale in the browser.
   */
  fmtServicePrice(price, { currency = '', country = undefined, language }) {
    // if price is not defined, just display empty
    if (!price) {
      return '';
    }
    // prepare the array of locale priorities, based on params
    const lang = language; // country.code === 'CHE' ? 'de' : language;
    // NOTE: country code in the model is now 3 chars, we need to strip it for regional code to 2 chars.
    const countryCodeShort = country.code.slice(0, 2);
    const regCode = country && lang ? `${lang}-${countryCodeShort}` : undefined;
    const preFmt = new Intl.NumberFormat(regCode, {
      style: 'currency',
      currency: country.currency,
      minimumFractionDigits: 2,
    })
      .formatToParts(price / 100)
      .map(({ type, value }) => {
        switch (type) {
          // skip currency that will be appended in front
          case 'currency':
            return ``;
          case 'group':
            return countryCodeShort === 'CH' ? `'` : value;
          default:
            return value;
        }
      })
      .reduce((string, part) => string + part);
    return `${currency} ${preFmt.trim()}`;
  },
};
