// This dispatcher page should perform additional validations and loading before forwarding user further.
import { mapGetters } from 'vuex';
import slugify from 'slugify';

export default {
  inject: ['systemService', 'companyService', 'alertService'],

  data() {
    return {
      /**
       * The status of the page, by default redirection to proper content.
       * Other valid values: forbidden, not_found.
       */
      status: 'redirection',
      company: null,
    };
  },
  async created() {
    await this.init();
  },
  computed: {
    ...mapGetters(['account', 'sysInfo']),

    /**
     * The message key is built upon a status of the page.
     */
    messageKey() {
      return `p.external_access.message.${this.status}`;
    },
    messageType() {
      return ['forbidden', 'not_found'].indexOf(this.status) >= 0 ? 'error' : 'message';
    },
  },
  methods: {
    /**
     * Page local handler of the error for specific display.
     *
     * NOTE: handling errors here because the app alert will not be used by default.
     * @param {Error} e - The error to handle.
     */
    handleError(e) {
      if (e.message.indexOf('error.entity_not_found') === 0) {
        this.status = 'not_found';
      } else if (e.message.indexOf('error.unauthorized') === 0) {
        // NOTE handling not authorized as forbidden
        this.status = 'forbidden';
      } else if (e.message.indexOf('error.forbidden') === 0) {
        // NOTE handling not authorized as forbidden
        this.status = 'forbidden';
      } else {
        // for the unknown, use the alert.
        this.alertService().showAlert(e.message);
      }
    },

    /**
     * Initializes the page based on working mode.
     */
    async init() {
      if (this.$router.currentRoute.params.ctx === 'status') {
        // check that status is passed from other page
        if (this.$router.currentRoute.params.status === 'forbidden') {
          this.status = 'forbidden';
          return;
        }
      } else if (this.$router.currentRoute.params.ctx === 'oa') {
        // order access
        const { cmd, langKey, aKey } = this.$router.currentRoute.query;
        // NOTE: redirecting according to command, right now only details
        this.goTo('eaOrderDetailsPage', { params: { cmd }, query: { langKey, aKey } });
      } else if (this.$router.currentRoute.params.ctx === 'b') {
        // booking without confirmation, this is weak detection for skip conf, but the key validation is done on server
        // const { scp } = this.$route.query;
        const scp = this.$router.currentRoute.params.simpleId;
        const { brFlt } = this.$router.currentRoute.query;

        // validate the company...
        try {
          const companyId = this.$router.currentRoute.params.id;
          const companyName = this.$router.currentRoute.params.name;
          if (companyId && companyName) {
            // the local slug is needed to preserve compatibility with previous already published lines (e.g. mar)
            const companyNameLocalSlug = slugify(companyName, { replacement: '_', trim: true, lower: true }).replace(
              /_*-+_*/g,
              '-',
            );
            // load the company to determine its type and basic settings
            const company = await this.companyService().retrieveCompany(companyId, { filter: { sflt: 'minData' } });
            // match the company name with the one specified in URL, discard if not matching
            // NOTE: now using simple key and slug to identify skip confirmation
            if (
              (company.nameSlug === companyName || company.nameSlug === companyNameLocalSlug) &&
              (!scp || (scp && company.ebScUrlEnabled))
            ) {
              // redirect to booking page according to the URL: no confirmation or with confirmation
              this.$router.replace({
                name: 'bookingRequestPage',
                query: {
                  companyId: this.$router.currentRoute.params.id,
                  // pass the param to booking
                  scp,
                  // The breq filter optional param
                  brFlt,
                },
              });
              return;
            }
          }
        } catch (e) {
          this.handleError(e);
          return;
        }
      }
      // nothing matched, appropriate status
      this.status = 'not_found';
    },
  },
};
