import dayjs from 'dayjs';

import pickerUtils from '../common/pickerUtils';
import commonConfig from '../common/constants';

const { DATE_FORMAT_API } = commonConfig;
const { DATE_PICKER_INT_FMT } = pickerUtils;

/**
 * The Mixin with time support methods.
 */
export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        /**
         * Converts the API standardized time HH:mm into localized variant depending on user's language.
         * @param {string} strTime - The string representation of API time that needs to be localized.
         * @returns {string} - The localized time string.
         */
        timeStrToLocalizedTimeStr(strTime) {
          if (strTime && strTime.trim()) {
            return dayjs(strTime, pickerUtils.TIME_PICKER_INT_FMT).locale(this.currentLanguage).format('LT');
          }
          return '';
        },
        /**
         * The custom sleep function based on promise for setTimeout.
         * @param {number} m - The number of milliseconds.
         * @returns {Promise<unknown>} - The promise.
         */
        sleep(m) {
          return new Promise((r) => setTimeout(r, m));
        },
        /**
         * Evaluates whether the specified date/time is in the past.
         * @param {Date|dayjs.Dayjs|string} date - The date to check.
         */
        isInThePast(date) {
          return dayjs(date).isBefore(dayjs());
        },
        /**
         * Evaluates whether the specified date/time is today.
         * @param {Date|dayjs.Dayjs|string} date - The date to check.
         * @param {string?} tz - The standard timezone string. If not specified the local timezone will be used.
         */
        isToday(date, tz = undefined) {
          // NOTE: again we need to be careful when converting, since the conversion from string would not be the same as from date
          // also, the utc conversion is used to match properly the dates in diff timezones
          // The startOf is also important to align to the date start before comparing
          // console.log(date, dayjs(date).tz(tz).toISOString(), dayjs.tz(date, DATE_FORMAT_API, tz).toISOString());
          const today = dayjs().tz(tz).startOf('date');
          const dateDJS = (typeof date === 'string' ? dayjs.tz(date, DATE_FORMAT_API, tz) : dayjs(date).tz(tz)).startOf(
            'date',
          );
          return dateDJS.utc().isSame(today.utc(), 'date');
        },
        /**
         * This method evaluates the current date in company's timezone.
         * In order to work, it requires that company instance is present in the scope of the component: this.company.
         *
         * Otherwise, the error is thrown.
         * @returns {string} - The evaluated current date string in company's timezone and in format adjusted for
         * application's REST API.
         */
        companyCurrentDateAPI() {
          if (this.company && this.company.city && this.company.city.tz) {
            return dayjs().tz(this.company.city.tz).format(DATE_FORMAT_API);
          }
          throw new Error(
            'Please initialize the company with city in component scope in order to have companyCurrentDateAPI working properly',
          );
        },
      },
      computed: {
        /**
         * The reactive variant of current date string with optional support of timezone - if company is present in the scope.
         * This computed function will check the presence of the company instance in the component
         * and try to use its timezone to display the current date in that timezone.
         * @returns {string} - The current date string in appropriate format for date picker.
         */
        dpCurrentDate() {
          let currentDate = dayjs();
          if (this.company) {
            // if there is a company instance available, use its timezone and convert to it
            currentDate = currentDate.tz(this.company.city.tz);
          }
          return currentDate.format(DATE_PICKER_INT_FMT);
        },
      },
    });
  },
};
